//Block definitions:
import {
    getCommandsToSend,
    getCommandToSend,
    getQueryToSend,
    QueryType,
    textToMemorableChunksOfSpeech
} from "@/blockly/blocks/common";
const maxLengthValidator = (newValue) => newValue.substring(0,60)

Blockly.Blocks['speech_say'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Say ");      
          //this old code forces inline strings. 
          // this.appendDummyInput()
          // .appendField("text input:")
          // .appendField(new Blockly.FieldTextInput('default text'),
          //     'text');
          //this new code is full text type that can be combined with other processing.
          this.appendValueInput("TEXT")
          .setCheck("String")
              //.setValidator(maxLengthValidator)
            

      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");

    }
  };

  Blockly.Blocks['speech_whisper'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Whisper ");                
          this.appendValueInput("TEXT")
          .setCheck("String")
              //.setValidator(maxLengthValidator)
            
      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");


    }
  };

  Blockly.Blocks['speech_askforinput'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Ask for input:");                
          this.appendValueInput("INPUT")
          .setCheck("String")
      this.setInputsInline(true);
      this.setOutput(true, "String");      
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");


    }
  };

  Blockly.Blocks['speech_askfornumber'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Ask for a number:");                
          this.appendValueInput("NUMBER")
          .setCheck("String")
      this.setInputsInline(true);      
      this.setOutput(true, "Number");
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");


    }
  };

  Blockly.Blocks['speech_writeandsay'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Write on board and say ");              
          this.appendValueInput("TEXT")
            .setCheck("String")
              //.setValidator(maxLengthValidator)
      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
  
    }

    
  };
    
  
  Blockly.Blocks['speech_writeonboard'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Write on board ");              
          this.appendValueInput("TEXT")
          .setCheck("String")
              //.setValidator(maxLengthValidator)
      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("speech_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
  
    }

    
  };
  


//Generators:

  
Blockly.JavaScript['speech_say'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    //let textChunks = textToMemorableChunksOfSpeech(value_text);
    //let sayCommands = textChunks.map(val =>  `"Say "+"${val}"`)
    //let timeouts = textChunks.map(val => val.length*80+1500)
    //TODO: Broken see trello
    //1 - this is splitting stuff up letter by letter!
    //2 - this doesn't work with blocks in the value text! It reads out the code!
    //return getCommandsToSend(sayCommands, timeouts, 'say', textChunks)
    //return getCommandsToSend(sayCommands, timeouts)
    return getCommandToSend(`'Say "'+${value_text}+'"'`, 2000)


  };

  Blockly.JavaScript['speech_whisper'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    //let textChunks = textToMemorableChunksOfSpeech(value_text);
    //let whisperCommands = textChunks.map(val =>  `'Whisper '+${val}`)
    //let timeouts = textChunks.map(val => val.length*80+1500)
    //TODO: Broken see trello
      //1 - this is splitting stuff up letter by letter!
      //2 - this doesn't work with blocks in the value text! It reads out the code!

      //return getCommandsToSend(whisperCommands, timeouts, 'whisper', textChunks)
    //return getCommandsToSend(whisperCommands, timeouts)
      return getCommandToSend(`"Whisper '"+${value_text}+"'"`, 2000)

  };

  Blockly.JavaScript['speech_askforinput'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'INPUT', Blockly.JavaScript.ORDER_ATOMIC) || "";
          
    
    let code = getQueryToSend(value_text, QueryType.text, 'Ask for input');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
    
  };

  Blockly.JavaScript['speech_askfornumber'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'NUMBER', Blockly.JavaScript.ORDER_ATOMIC) || "";
          
    
    let code = getQueryToSend(value_text, QueryType.number, 'Ask for input');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['speech_writeandsay'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    let textChunks = textToMemorableChunksOfSpeech(value_text);
    let sayCommands = textChunks.map(val =>  `"Write on board and say "+"${val}"`)
    let timeouts = textChunks.map(val => val.length*500+1500)
    //TODO: Broken see trello
    //return getCommandsToSend(sayCommands, timeouts, 'say', textChunks)
    return getCommandsToSend(sayCommands, timeouts)
    

  };

  Blockly.JavaScript['speech_writeonboard'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    let textChunks = textToMemorableChunksOfSpeech(value_text);
    let sayCommands = textChunks.map(val =>  `"Write on board "+"${val}"`)
    let timeouts = textChunks.map(val => val.length*80+1500)
    //TODO: Broken see trello
    //return getCommandsToSend(sayCommands, timeouts, 'say', textChunks)
    return getCommandsToSend(sayCommands, timeouts)
    

  };

     