
const blockStyles = {

    // list_blocks:{colourPrimary:"260"},
    logic_blocks:{colourPrimary:"173"},
    legs_blocks: {colourPrimary:"277"},
    loop_blocks:{colourPrimary:"193"},
    math_blocks:{colourPrimary:"231"},
    procedure_blocks:{colourPrimary:"28"},
    text_blocks:{colourPrimary:"160"},
    variable_blocks:{colourPrimary:"45"},
    arm_blocks: {colourPrimary: "273"},
    sensing_blocks: {colourPrimary: "348"},
    speech_blocks: {colourPrimary: "28"},
    movement_blocks: {colourPrimary: "321"},
    storytelling_blocks: {colourPrimary: "28"},
    remote_blocks: {colourPrimary: "28"},
    event_blocks: {colourPrimary: "45"}


}

const categoryStyles = {
    "logic_category": {
        "colour": "173",
    },
    "loop_category": {
        "colour": "193",
    },
    "legs_category": {
        "colour": "277",
    },
    "math_category":{
        "colour": "231"
    },
    "text_category":{
        "colour": "160"
    },
    "arms_category":{
        "colour": "273"
    },
    "sensing_category":{
        "colour": "348"
    },
    "variable_category":{
        "colour": "45"
    },
    "movement_category":{
        "colour": "321"
    },
    "storytelling_category":{
        "colour": "28"
    },
    "events_category":{
        "colour": "45"
    },
    "remote_category":{
        "colour": "28"
    },
    "procedure_category":{
        "colour": "28"
    },
    "speech_category":{
        "colour": "28"
    }

}

const fontStyle = {
    "size": 14
}

export const lexBlocklyTheme = Blockly.Theme.defineTheme('lexTheme', {
    'blockStyles': blockStyles,
    'categoryStyles': categoryStyles,
    'fontStyle': fontStyle
});