const RECONNECT_TIME = 5000;
export const WebSocketClient = function(newUrl, keepAlivePropertyName, authKey) {
    let webSocket,
        //hasConnection = false,
        messageCallback,
        openCallback,
        errorCallback,
        closeCallback,
        url = newUrl,
        keepAliveProperty = keepAlivePropertyName || "instruction";

    const init = function(onMessageHandler, onOpenHandler, onErrorHandler, onCloseHandler){

        messageCallback = onMessageHandler;
        openCallback = onOpenHandler
            closeCallback = onCloseHandler
        errorCallback = onErrorHandler
            //url = newUrl;
            connect();

    }
    const connect = function(){
        webSocket = new WebSocket(url);
        webSocket.onopen = onopen;
        webSocket.onclose = onclose;
        webSocket.onerror = onerror;
        webSocket.onmessage = onmessage;
    }
    const onopen = function(){
        console.log('connection established');
        //hasConnection = true;
        if(openCallback){
            openCallback();
        }
    }

    const onclose = function(e){
        //hasConnection = false
        if(e.wasClean){
            console.log('closed cleanly '+e.code);
        }
        else{
            Logger.error("Lost connection to wS")
        }

        if(closeCallback)
        {
            closeCallback(e);
        }
        //try to reconnect
        setTimeout(connect, RECONNECT_TIME);
    }
    const onerror = function(e){
        errorCallback(e);

    }
    const onmessage = function(event){
        console.log('received '+event.data);
        let data = JSON.parse(event.data);
        if(data.instruction && data.instruction === "keepalive"){
            let keepAliveMsg = {}
            keepAliveMsg[keepAliveProperty] = "keepalive"
            if(authKey){
                keepAliveMsg.authKey = authKey
            }
            sendToServer(keepAliveMsg)
        } else {
            messageCallback(data)
        }
    }

    const sendToServer = function(json){
        let message = JSON.stringify(json);
        console.log("Sending "+message)
        webSocket.send(message);
    }
    return {
        init: init,
        send: sendToServer,
        websocket: webSocket
    }
}
