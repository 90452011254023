import Vue from 'vue'
import VueRouter from 'vue-router'

import FacilitatorLogin from "@/views/FacilitatorLogin.vue";
import EngineerView from "@/views/EngineerView.vue";
import {userService} from "@/services/userservice";
import store from  "@/store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: EngineerView
  },
  { path: '/login',
    name: 'login',
    component: FacilitatorLogin
  },

  {
    path: '/admin',
    name: 'facilitator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FacilitatorView.vue')
  },
  {
    path: '/customiseBlocks',
    name: 'customiseBlocks',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomiseBlocksView.vue')
  },
  {
    path: '/engineer',
    name: 'engineer',
    component: EngineerView
  },
  {
    path: '/engineer/primary',
    name: 'primaryEngineer',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrimaryEngineerView.vue')
  },
  {
    path: '/breakdown',
    redirect: '/engineer/primary'
  },
  {
    path: '/robotstore',
    name: 'betaChat',
    component: () => import(/* webpackChunkName: "about" */ '../views/BetaChatView.vue')
  },
  {
    path: '/manageScripts',
    name: 'manageScripts',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatScriptManagerView.vue')
  },
  {
    path: '/personality',
    name: 'personality',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonalityView.vue')
  },
  //otherwise redirect to home
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/', '/engineer', '/engineer/primary', '/robotstore'];
  const authRequired = !publicPages.includes(to.path);
  //TODO: do we want a timeout on logging in?
  if(authRequired){

    let authData= userService.authoriseWithSavedData()
    authData.then(auth =>{
        store.state.facilitatorAuthKey = auth
        next();
      }, ()=> {
      return next({
        path: '/login',
        query: { returnUrl: to.path }
      });

    })
  }
  else{
    next();
  }


})

export default router
