
const {getCommandToSend, setupBlockWithInput, setupBlockDropdownArgument, setupBlockNoArg,
    setupBlockWithInputAndDropdown, degreesToHumanFriendlyTurns
} = require("@/blockly/blocks/common");
//Block definitions:

//these method calls set up both block and generator:
setupBlockWithInput("move_walkforward", "Take {} steps forwards",(steps)=>1500+1000*steps,
    {
        textForLex: "Walk forward {} steps",
        argName: "STEPS",
        style: "movement_blocks",
        inputType: "Number"
    } )

setupBlockWithInput("move_walkbackwards", "Take {} steps backwards",(steps)=>1500+1000*steps,
    {
        textForLex: "Walk backwards {} steps",
        argName: "STEPS",
        style: "movement_blocks",
        inputType: "Number"
    } )

setupBlockDropdownArgument("move_hop", "Hop on to my {} foot", ["left", "right"], 2000, {style: "movement_blocks", textForLex: "Hop on to your {} foot"})

setupBlockNoArg("move_stop", "Stop", 1000, {style: "movement_blocks"});

setupBlockNoArg("move_retracesteps", "Return to the start", 4000, {style: "movement_blocks"});

setupBlockNoArg("move_walktowardsgrandma", "Walk towards Grandma", 3000, {style: "movement_blocks"});

setupBlockWithInputAndDropdown("move_walktowardsoption", "Walk towards {dropdown}",2000,
    {
        argName: "DESCRIPTION",
        style: "movement_blocks",
        inputType: "String",
        dropdownOptions: ["the door", "the dustbin", "the window", "the teacher", "a child"],
        //nextStatement: false

    } )


//below here, we are created block and generator manually

  Blockly.Blocks['move_stepsideways'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Take");
      this.appendValueInput("STEPS")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField("steps to my ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "direction")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['move_turn'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Turn");
      this.appendValueInput("DEGREES")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField("degrees to my ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "direction")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['move_crouch'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Crouch");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['move_sit'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Sit");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['move_stand'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Stand");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['move_jump'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Jump");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['move_crawlforward'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Crawl");
      this.appendValueInput("STEPS")
          .setCheck("Number")          
          this.appendDummyInput()                
          .appendField("steps forward");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.Blocks['move_crawlbackward'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Crawl");
      this.appendValueInput("STEPS")
          .setCheck("Number")          
          this.appendDummyInput()                
          .appendField("steps backwards");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("movement_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };




//Generators:

  
  Blockly.JavaScript['move_stepsideways'] = function(block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'STEPS', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    let direction = block.getFieldValue('direction');    
    //warning: can cause problems. see trello
    //return getCommandToSend(`"Move " + ${steps} + " steps ${direction}"`, 1500+1000*steps, 'step'+toTitleCase(direction), steps);
    return getCommandToSend(`"Move " + ${steps} + " steps ${direction}"`, 1500+1000*steps);
    
  };

  Blockly.JavaScript['move_turn'] = function(block) {
    let degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC);
    let direction = block.getFieldValue('direction');
    let isClockwise = direction === "right"
    //see trello
    //return getCommandToSend(`"Turn ${direction}" + "${degreesToHumanFriendlyTurns(degrees, isClockwise)}"`, 6000, 'turn'+toTitleCase(direction), degrees);
    return getCommandToSend(`"Turn ${direction}" + "${degreesToHumanFriendlyTurns(degrees, isClockwise)}"`, Math.round(2500+(degrees/360*2000)));

  };


  Blockly.JavaScript['move_crouch'] = function() {
    return getCommandToSend('"crouch"', 1500, 'crouch');

  };

  Blockly.JavaScript['move_jump'] = function() {
    return getCommandToSend('"jump"', 1000, 'jump');

  };

  Blockly.JavaScript['move_sit'] = function() {
    return getCommandToSend('"sit"', 3000, 'sit');

  };

  Blockly.JavaScript['move_stand'] = function() {
    return getCommandToSend('"stand"', 3000, 'stand');

  };


  
    
Blockly.JavaScript['move_crawlforward'] = function(block) {
  let value_steps = Blockly.JavaScript.valueToCode(block, 'STEPS', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  return getCommandToSend('"Crawl forward " + ' + value_steps + ' + " steps"', 1500+1000*value_steps);

};

Blockly.JavaScript['move_crawlbackward'] = function(block) {
  let value_steps = Blockly.JavaScript.valueToCode(block, 'STEPS', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  return getCommandToSend('"Crawl backwards " + ' + value_steps + ' + " steps"', 1500+1000*value_steps);

};


  

