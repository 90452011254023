import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.devtools = true
Vue.config.productionTip = false
//these are for blockly to behave
Vue.config.ignoredElements = ['xml', 'category', 'block']

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//axios.defaults.baseURL = `${window.location.protocol}${window.location.hostname}${process.env.API_SERVER_PORT}`;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080';


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


