export const EVENTS_CATEGORY_XML=`<category name="Events" categorystyle="events_category" iconclass="blocklyTreeIconCustom events" css-container="blocklyToolboxCategory events">
    <label text="Events" web-class="blocklyFlyoutHeading"></label>`
export const EVENTS_WHEN_XML=`<block type="events_handler"></block>`
export const EVENTS_WAIT_SECONDS_XML=`<block type="wait_waitseconds">
        <value name="SECONDS">
            <shadow type="math_number">
                <field name="NUM">2</field>
            </shadow>
        </value>
    </block>`
export const EVENTS_WAIT_WHILE_UNTIL_XML=`<block type="wait_waitwhileuntil"></block>`