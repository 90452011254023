var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.status === "active" && _vm.inTaskOverview === true
      ? _c("div", { staticClass: "overview-container" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.overviewHtml) } }),
          _c("span", { staticClass: "show-blocks-link" }, [
            _c(
              "a",
              {
                staticClass: "btn",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.viewBlocks()
                  },
                },
              },
              [_vm._v("View blocks for task")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.status === "active" && _vm.inTaskOverview === false
      ? _c(
          "div",
          { staticClass: "task-details" },
          [
            _vm.title ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
            _c("div", {
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
            _vm.isPrimaryUser === false
              ? _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "pause-modal",
                      title: "LexEdit is paused",
                      "no-close-on-esc": true,
                      "no-close-on-backdrop": true,
                      "hide-header-close": true,
                      "hide-footer": true,
                    },
                    model: {
                      value: _vm.isPaused,
                      callback: function ($$v) {
                        _vm.isPaused = $$v
                      },
                      expression: "isPaused",
                    },
                  },
                  [
                    _c("p", { staticClass: "my-4" }, [
                      _vm._v("Please wait for further instructions..."),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }