var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lex-runner section header" },
    [
      _vm.authCode !== null
        ? [
            _c(
              "div",
              { staticClass: "lex-controls" },
              [
                _vm.pendingRemoteResponseId === null
                  ? [
                      _c("span", { staticClass: "connected-to-lex" }, [
                        _vm._v("You are connected to LeX"),
                      ]),
                      _vm.hasResetLex === false && _vm.isRunning === false
                        ? _c(
                            "button",
                            {
                              staticClass: "reset-btn",
                              attrs: {
                                title: "Reset LeX",
                                disabled: _vm.resetDisabled,
                              },
                              on: { click: _vm.resetLex },
                            },
                            [_vm._v(_vm._s(_vm.resetButtonName))]
                          )
                        : _vm._e(),
                      _vm.hasResetLex !== false
                        ? _c(
                            "button",
                            {
                              staticClass: "play-btn",
                              attrs: { title: "Run task on LeX" },
                              on: { click: _vm.runAllCodeOnLex },
                            },
                            [_vm._v("Run on LeX")]
                          )
                        : _vm._e(),
                      _vm.isRunning === true
                        ? _c(
                            "button",
                            {
                              staticClass: "stop-btn",
                              attrs: { title: "Stop" },
                              on: { click: _vm.stopExecution },
                            },
                            [_vm._v("Stop")]
                          )
                        : _vm._e(),
                      _vm.markedSuccessful === true
                        ? _c("button", { on: { click: _vm.uploadToLex } }, [
                            _vm._v("Upload to LeX"),
                          ])
                        : _vm._e(),
                    ]
                  : _c("div", [_vm._v(" Connected remotely ")]),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.executionFailed === true
        ? _c("div", { staticClass: "warning" }, [
            _vm.customErrorMessage
              ? _c("span", [_vm._v(" " + _vm._s(_vm.customErrorMessage) + " ")])
              : _c("span", [
                  _vm._v(" LeX had an error running your program. "),
                ]),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "memory-wipe",
            title: "Performing LeXMemoryClean",
            "no-close-on-esc": true,
            "no-close-on-backdrop": true,
            "hide-header-close": true,
            "hide-footer": true,
          },
          model: {
            value: _vm.memoryCleanInProgress,
            callback: function ($$v) {
              _vm.memoryCleanInProgress = $$v
            },
            expression: "memoryCleanInProgress",
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v("Cleaning memory...Please stand by"),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "upload-modal",
            title: "Uploading to LeX",
            "no-close-on-esc": true,
            "no-close-on-backdrop": true,
            "hide-header-close": true,
            "hide-footer": true,
          },
          model: {
            value: _vm.uploadInProgress,
            callback: function ($$v) {
              _vm.uploadInProgress = $$v
            },
            expression: "uploadInProgress",
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v("Please wait while your instructions upload..."),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }