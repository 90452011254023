<template>
  <span></span>
</template>

<script>
//TODO: Do we want to reimplement this as a mixin?
//TODO: Do we want to integrate the other local storage stuff in here?
//TODO: refactor to use store...
import {mapState} from "vuex";

const STORAGE_EXPIRY_MS = 600000;
export default {
  name: 'LocalCache',
  props: {
    currentWorkspace: String,
    newStudentName: String,
    studentId: String,
    loginConfirmed: Boolean
  },
  data: function () {
    return {
      storedDataFromPreviousSession: null,
      taskId: null,
      studentName: null
    }
  },
  computed: mapState(["hasActiveTask"]),
  emits: ["student-name-loaded", "load-saved-blocks"],
  created() {
    this.$root.$on('forget-previous-login', this.invalidateStorage);

  },
  beforeDestroy() {
    this.$root.$off('forget-previous-login', this.invalidateStorage);

  },
  mounted: function () {

    this.storedDataFromPreviousSession = this.retrieveFromStorage();

    if (this.storedDataFromPreviousSession) {
      this.$emit("student-name-loaded", this.storedDataFromPreviousSession.studentName)
      this.updateStorage();
    }
  },
  watch: {
    hasActiveTask: function(newValue){
      if(newValue === true){
        this.onTaskStarted(this.$store.state.activeTask)
      }
      if(newValue === false){
        this.invalidateStorage()
      }
    },
    currentWorkspace: function () {
      this.updateStorage();
    },
    newStudentName: {
      handler: 'onNewStudentNameChanged',
      immediate: true
    },
    studentId: function (studentId) {
      if (studentId) {

        this.storedDataFromPreviousSession = this.retrieveFromStorage();
        if (this.storedDataFromPreviousSession != null) {


          if (this.storedDataFromPreviousSession.studentId && this.storedDataFromPreviousSession.studentId !== studentId) {

            this.$root.$emit("student-id-changed", {
              old: this.storedDataFromPreviousSession.studentId,
              new: this.studentId
            });
            this.updateStorage();
          }
        }

      }
    },
    loginConfirmed: function (confirmed) {
      if (confirmed === true) {
        this.checkForStoredTask();
      }
    }
  },
  methods: {
    onNewStudentNameChanged: function (name) {
      if (name) {
        this.studentName = name;
      }
    },
    onTaskStarted: function (taskDetails) {
      this.taskId = taskDetails.sessionId;
      this.checkForStoredTask()
    },
    checkForStoredTask: function () {
      //wait till we have a task id (ie task is started) and a student name (ie student login confirmed):

      if (this.taskId && this.studentName) {
        if (this.storedDataFromPreviousSession && this.taskId === this.storedDataFromPreviousSession.currentTaskId) {
          //let this cycle finish (so any other components can mount)
          this.$nextTick(function () {
            this.$emit("load-saved-blocks", {workspace: this.storedDataFromPreviousSession.workspace});
            this.$root.$emit("load-saved-blocks", {workspace: this.storedDataFromPreviousSession.workspace});
          });
        }
      }

    },
    updateStorage: function () {
      if (typeof (Storage) !== "undefined" && this.taskId) {
        let storageData = {
          studentName: this.studentName,
          studentId: this.studentId,
          timestamp: Date.now(),
          currentTaskId: this.taskId,
          workspace: this.currentWorkspace
        }
        localStorage.setItem("storedData", JSON.stringify(storageData));
      } else {
        console.log("storage not available");
      }
    },
    retrieveFromStorage: () => {
      let storedData = null;
      if (typeof (Storage) !== "undefined") {
        let inStorage = localStorage.getItem("storedData");
        if (inStorage) {
          let parsedData = JSON.parse(inStorage);
          if (parsedData && parsedData.timestamp && Date.now() - parsedData.timestamp < STORAGE_EXPIRY_MS) {
            storedData = parsedData;
          }
        }
      } else {
        console.log("storage not available");
      }

      return storedData;

    },
    invalidateStorage: function () {

      //TODO: rewrite with localForage
      console.log("invalidate storage");
      this.storedDataFromPreviousSession = null;
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem("storedData", null);
      }
    }

  }
}
</script>


