import { render, staticRenderFns } from "./BlocklyToolbox.vue?vue&type=template&id=5b30baae&scoped=true"
import script from "./BlocklyToolbox.vue?vue&type=script&lang=js"
export * from "./BlocklyToolbox.vue?vue&type=script&lang=js"
import style0 from "./BlocklyToolbox.vue?vue&type=style&index=0&id=5b30baae&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b30baae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_e81d46a5/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b30baae')) {
      api.createRecord('5b30baae', component.options)
    } else {
      api.reload('5b30baae', component.options)
    }
    module.hot.accept("./BlocklyToolbox.vue?vue&type=template&id=5b30baae&scoped=true", function () {
      api.rerender('5b30baae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BlocklyToolbox.vue"
export default component.exports