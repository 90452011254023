<template>
  <div class="lex-runner section header">
    <template v-if="authCode !== null">
      <div class="lex-controls">
        <template  v-if="pendingRemoteResponseId === null">
          <span class="connected-to-lex">You are connected to LeX</span>
          <button class="reset-btn" title="Reset LeX" v-if="hasResetLex === false && isRunning === false" :disabled="resetDisabled" v-on:click="resetLex">{{resetButtonName}}</button>
          <button class="play-btn" title="Run task on LeX" v-if="hasResetLex !== false"  v-on:click="runAllCodeOnLex">Run on LeX</button>
          <button class="stop-btn" title="Stop" v-if="isRunning === true"  v-on:click="stopExecution">Stop</button>
          <button v-if="markedSuccessful === true" v-on:click="uploadToLex">Upload to LeX</button>
        </template>
        <div v-else>
          Connected remotely
        </div>
      </div>
    </template>
    <!--    <template v-else>    -->
    <!--                  &lt;!&ndash; <button v-on:click="runOnSim" disabled="disabled" title="temporarily disabled simulator">Run on Sim</button> &ndash;&gt;-->
    <!--    -->
    <!--    </template>  -->
    <div v-if="executionFailed === true" class="warning">
        <span v-if="customErrorMessage">
            {{customErrorMessage}}
        </span>
      <span v-else>
            LeX had an error running your program.
        </span>
    </div>
    <b-modal id="memory-wipe" title="Performing LeXMemoryClean"
             v-model="memoryCleanInProgress"
             :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true"
             :hide-footer="true">
      <p class="my-4">Cleaning memory...Please stand by</p>
    </b-modal>
    <b-modal id="upload-modal" title="Uploading to LeX"
             v-model="uploadInProgress"
             :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true"
             :hide-footer="true">
      <p class="my-4">Please wait while your instructions upload...</p>
    </b-modal>

  </div>
</template>

<script>
import $ from 'jquery';
require('@/util/interpreter')
import {InterpreterApi} from "@/util/interpreterApi"
import {BModal} from "bootstrap-vue";


export default {
  name: 'LexRunner',
  components: {BModal},
  props: {
    authCode: String,
    currentTaskMessage: Object,
    codeToExecute: String,
    remoteBlockMessage: Object
  },
  data: function () {
    return {
      taskId: null,
      hasResetLex: false,
      resetDisabled: false,
      uploadInProgress: false,
      memoryCleanInProgress: false,
      isRunning: false,
      executionFailed: false,
      customErrorMessage: null,
      runningProcessId: null,
      markedSuccessful: false,
      pendingRemoteResponseId: null,
      notifyLexOnReset: true,
      eventsForTask: null,
      resetButtonName: "Reset",
    }
  },
  emit: ["highlight-block", "reset", "remote-execution-complete", "instruction"],
  watch: {
    currentTaskMessage: {
      handler: 'processCurrentTaskMessage',
      immediate: true
    },
    remoteBlockMessage: {
      handler: 'processRemoteBlockMessage'
    }
  },
  //for interpreting the blocks:
  interpreter: null,
  //for running code
  runner: null,
  created() {
    this.$root.$on('remote-code-generated', this.onRemoteCodeGenerated);
    this.$root.$on('event-code-generated', this.onEventCodeGenerated);
  },
  beforeDestroy() {
    this.$root.$off('remote-code-generated', this.onRemoteCodeGenerated);
    this.$root.$off('event-code-generated', this.onEventCodeGenerated);

  },
  methods: {
    resetLex: function () {
      this.resetInterpreter();
      this.executionFailed = false;
      if (this.notifyLexOnReset) {
        this.messageLex(this.resetButtonName, "RESET")
            .done(() => {
              this.resetDisabled = true
              window.setTimeout(() => {
                this.resetDisabled = false
                this.hasResetLex = true;
                this.$emit("reset")
              }, 3000)

            })
            .fail(jqXHR => Logger.error(JSON.stringify(jqXHR)))
      } else {
        this.resetDisabled = true
        window.setTimeout(() => {
          this.resetDisabled = false
          this.hasResetLex = true;
          this.$emit("reset")
        }, 3000)
      }
    },
    processRemoteBlockMessage: function (message) {
      let messageType = message.message;
      if (messageType === "executeRemoteBlock") {
        if (this.pendingRemoteResponseId) {
          //todo: how to handle situation where teacher asks to override remote block.
          this.handleRemoteError('remote block already running', 400, message.responseId);
        } else if (!message.blockName || !message.responseId) {
          this.handleRemoteError('Invalid execute remote message', 500, message.responseId);
        } else {

          this.pendingRemoteResponseId = message.responseId;
          this.$root.$emit("generate-remote-block-code", {
            type: 'remoteBlock',
            blockName: message.blockName,
            responseId: message.responseId
          });
        }
      } else if (messageType === "stopRemoteExecution") {
        if (this.pendingRemoteResponseId) {
          this.stopExecution();
          this.pendingRemoteResponseId = null;
        }
      }
    },
    runOnSim: function () {
      this.hideSimulator = false;
      this.runOnLex();
    },
    runAllCodeOnLex: function () {
      this.$root.$emit("send-student-message", {message: "startLocalExecution"});
      this.runOnLex(this.codeToExecute);
    },
    onRemoteCodeGenerated: function (details) {
      if (details.responseId === this.pendingRemoteResponseId) {
        if (details.error) {
          this.handleRemoteError(details.error, 404, this.pendingRemoteResponseId);
        } else {
          this.runOnLex(details.code);
        }
      } else {
        this.handleRemoteError("Response id doesn't match", 500, details.responseId);
      }

    },
    onEventCodeGenerated: function (details) {
      this.resetInterpreter();
      this.pendingRemoteResponseId = null;
      this.runOnLex(details.code);


    },
    handleRemoteError: function (error, status, responseId) {
      if (error) {
        Logger.error(error);
      }
      let msg = {
        message: 'remoteBlockExecuted',
        responseId: responseId,
        status: status || 500,
        responseData: error
      }
      this.pendingRemoteResponseId = null;
      this.$emit("remote-execution-complete", msg);
    },
    runOnLex: function (codeToRun) {
      this.executionFailed = false;
      this.isRunning = true;
      this.hasResetLex = false;
      //see: https://neil.fraser.name/software/JS-Interpreter/docs.html
      if (!this.$options.interpreter) {

        InterpreterApi.setCallbacks(() => this.authCode, this.highlight, this.abort, this.remoteBlockExecutionStarted, this.remoteBlockExecutionEnded);


        this.$options.interpreter = new Interpreter(codeToRun, InterpreterApi.setupApi);
        let instance = this;
        this.$options.runner = function () {
          if (instance.$options.interpreter) {
            try {
              let hasMore = instance.$options.interpreter.run();
              if (hasMore && instance.isRunning === true) {
                // Execution is currently blocked by some async call.
                // Try again later.
                instance.runningProcessId = setTimeout(instance.$options.runner, 20);
              } else {
                //program is complete
                let lastValue = instance.$options.interpreter.value;
                instance.resetInterpreter();
                instance.executionComplete(lastValue);
              }
            } catch (e) {
              Logger.error("oh dear! ", e);
              instance.abort(e)

            }
          }
        };
        this.$options.runner();
      }
    },
    executionComplete: function (lastValue) {
      //leave the stop button up if we are not in an task with events
      if(!this.eventsForTask){
        this.isRunning = false;
      }
      if (this.pendingRemoteResponseId) {

        this.$emit("remote-execution-complete", {
          message: "remoteBlockExecuted",
          responseId: this.pendingRemoteResponseId,
          status: lastValue ? 200 : 204,
          responseData: lastValue
        });
        this.pendingRemoteResponseId = null;
      } else {
        //taking this out for now - we don't want to send this until the program is explicitly stopped
        //this.$root.$emit("send-student-message", {message: "stopLocalExecution" });
      }
      this.$root.$emit("execution-complete");
    },
    remoteBlockExecutionStarted(studentId) {
      this.$root.$emit("execute-started-on", studentId);
    },
    remoteBlockExecutionEnded(studentId) {
      this.$root.$emit("execute-finished-on", studentId);
    },
    uploadToLex: function () {
      let instance = this;
      //this hardcoded value is used for one particular point in the story. Might want to make this more flexible in future...
      if (this.taskId === "DANCE") {
        this.$bvModal.msgBoxConfirm('You are uploading new instructions. Do you want to run LeXMemoryClean for optimum performance?', {
          title: 'Message from Beta',
          okTitle: 'YES',
          cancelTitle: 'NO'
        }).then(value => {
              if (value === true) {
                this.memoryCleanInProgress = true;
                this.messageLex('Running Lex MemoryClean', 'MEMORYCLEAN');
                setTimeout(() => {
                  instance.memoryCleanInProgress = false;
                  instance.uploadInProgress = true
                  this.messageLex('uploading new instructions', 'UPLOAD');
                  setTimeout(() => this.finishUpload(), 5000);
                  }, 5000);
              }
            })
            .catch(() => {
              // An error occurred
            })
      } else {
        this.uploadInProgress = true;
        this.messageLex('uploading new instructions', 'UPLOAD');
        setTimeout(() => this.finishUpload(), 3000);
      }

    },
    finishUpload: function(){
      this.uploadInProgress = false
      this.$emit('instruction', {
        'instruction': 'stopSession'
      });
    },
    messageLex: function (message, displayImageName) {
      let displayImageParam = displayImageName ? `&displayImage=${encodeURI(displayImageName)}` : ""
      return $.ajax({
        url: `/api/broadcast/sendMessage?content=${encodeURI(message)}${displayImageParam}`,
        type: 'PUT',
        headers: {'Authorization': this.authCode}
      })
    },
    resetInterpreter: function () {
      this.$options.interpreter = null;
      if (this.runningProcessId) {
        clearTimeout(this.runningProcessId);
        this.runningProcessId = null;
      }
      //if (this.$options.runner) {
      //clearTimeout(this.$options.runner);
      this.$options.runner = null;
      //}
      this.hasReset = false;
      //TODO: hide simulator here
    },
    stopExecution: function () {
      this.isRunning = false;
      this.messageLex("program stopped", 'DIAGNOSTICS');
      if (this.pendingRemoteResponseId) {
        this.$root.$emit('stop-remote-execution');
      } else {
        this.$root.$emit("send-student-message", {message: "stopLocalExecution"});
      }

    },
    abort: function (rawError) {

      this.isRunning = false;
      this.hasResetLex = false;
      let error;
      if (typeof (rawError) === "object") {
        error = rawError
      } else if (typeof (rawError) === "string") {
        //could be a stringified object:
        try {
          error = JSON.parse(rawError);
        } catch (e) {
          //error was just a plain string. Setup default values
          error = {
            code: 500,
            message: rawError
          }
        }
      } else {
        //unknown error. Apply a default value
        error = {code: 500}
      }
      //apply any custom error handling:
      if (error.code === 404) {
        error.humanMessage = "You tried to run a team block but the engineer was not connected."
      }

      this.resetInterpreter();
      this.customErrorMessage = error.humanMessage || null
      this.executionFailed = true;
      if (this.pendingRemoteResponseId) {
        this.handleRemoteError("error executing blocks", error.code, this.pendingRemoteResponseId);
      } else {
        this.messageLex("Error running blocks", 'ERROR')
        this.$root.$emit("send-student-message", {message: "stopLocalExecution"});
      }
    },
    highlight: function (id) {
      this.$emit("highlight-block", id);
      this.$root.$emit("block-highlighted", id)
    },
    processCurrentTaskMessage: function (data) {
      let msgType = data.message;
      if (msgType === 'startSession') {
        this.hasResetLex = false;
        this.taskId = data.id;
        this.markedSuccessful = false;
        this.resetButtonName = data.resetButtonName || "Reset";
        this.notifyLexOnReset = data.resetNotify || false;
        this.eventsForTask = data.events;
      } else if (msgType === 'stopSession') {
        this.taskId = null;
        this.eventsForTask = null;
      } else if (msgType === 'markSuccessful') {
        this.markedSuccessful = true;
      }
    },

  },
}
</script>

