var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasActiveTask === false
        ? _c("div", { staticClass: "login" }, [
            _vm._m(0),
            _c("h2", [_vm._v("Welcome to LexEdit v 450.1.2")]),
            _vm.loginStage === 0 && _vm.studentName !== null
              ? _c("span", [
                  _c("span", { staticClass: "loading-bar stage-0" }),
                  _c("p", [
                    _vm._v(
                      "There was a previous login from " +
                        _vm._s(_vm.studentName)
                    ),
                  ]),
                  _c("p", [_vm._v("Is that you?")]),
                  _c("button", { on: { click: _vm.completeLogin } }, [
                    _vm._v("Yes"),
                  ]),
                  _c("button", { on: { click: _vm.forgetPreviousLogin } }, [
                    _vm._v("No"),
                  ]),
                ])
              : _vm._e(),
            _vm.loginStage === 0 && _vm.studentName === null
              ? _c("span", [
                  _c("span", { staticClass: "loading-bar stage-1" }),
                  _vm._m(1),
                  _c("p", [
                    _vm._v(
                      "This website will let you debug and add new instructions to your Edubot. "
                    ),
                  ]),
                  _c("p", { staticClass: "check" }, [
                    _vm._v("You must be an authorised engineer to continue."),
                  ]),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.loginStage++
                        },
                      },
                    },
                    [_vm._v("Continue")]
                  ),
                ])
              : _vm._e(),
            _vm.loginStage === 1
              ? _c("span", [
                  _c("span", { staticClass: "loading-bar stage-2" }),
                  _c("p", [_vm._v("Are you an authorised user?")]),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.loginStage++
                        },
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.loginStage--
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                ])
              : _vm._e(),
            _vm.loginStage === 2
              ? _c("span", [
                  _c("span", { staticClass: "loading-bar stage-3" }),
                  _c("p", [_vm._v("Please enter your name or names:")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.studentName,
                        expression: "studentName",
                        modifiers: { trim: true },
                      },
                    ],
                    domProps: { value: _vm.studentName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.studentName = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm.studentName !== null && _vm.studentName !== ""
                    ? _c("button", { on: { click: _vm.completeLogin } }, [
                        _vm._v("Continue"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      (_vm.hasActiveTask === false && _vm.loginStage === 3) ||
      (_vm.hasActiveTask === true && _vm.isWaitingAuth === true)
        ? _c(
            "div",
            { staticClass: "login" },
            [
              _vm.hasActiveTask === true
                ? [
                    _vm._m(2),
                    _c("h2", [_vm._v("Welcome to LexEdit v 450.2.4")]),
                  ]
                : _vm._e(),
              _c("span", { staticClass: "loading-bar stage-5" }),
              _vm._m(3),
            ],
            2
          )
        : _vm._e(),
      _vm.hasActiveTask === true && _vm.isWaitingAuth === false
        ? _c("header", { staticClass: "user-name" }, [
            _vm._m(4),
            _c("div", { staticClass: "box" }, [
              _c("span", { staticClass: "welcome" }, [
                _vm._v("Welcome, " + _vm._s(_vm.studentName)),
              ]),
            ]),
            _vm._m(5),
          ])
        : _vm._e(),
      _c("local-cache", {
        attrs: {
          "login-confirmed": _vm.loginStage === 3,
          "new-student-name": _vm.studentName,
          "current-workspace": _vm.currentWorkspace,
          "student-id": _vm.studentId,
        },
        on: { "student-name-loaded": _vm.studentNameLoaded },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "user-name" }, [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "classbot-logo",
          attrs: {
            src: require("@/assets/img/logos/lex-logo-full.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "goat-logo",
          attrs: {
            src: require("@/assets/img/logos/GOAT-logo.png"),
            alt: "The Government Office of Aspirational Technology",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("em", [
        _vm._v("Warning: This interface is for authorised engineers only"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "user-name" }, [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "classbot-logo",
          attrs: {
            src: require("@/assets/img/logos/lex-logo-full.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticClass: "goat-logo",
          attrs: {
            src: require("@/assets/img/logos/GOAT-logo.png"),
            alt: "The Government Office of Aspirational Technology",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "loading-msg" }, [
        _vm._v("Connecting to Edubot 450 LeX... Loading"),
      ]),
      _c("img", {
        staticClass: "loading-gif",
        attrs: {
          src: require("@/assets/img/icons/blue-loading-gif-transparent.gif"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("img", {
        staticClass: "classbot-logo",
        attrs: {
          src: require("@/assets/img/logos/lex-logo-full.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box" }, [
      _c("img", {
        staticClass: "goat-logo",
        attrs: {
          src: require("@/assets/img/logos/GOAT-logo.png"),
          alt: "The Government Office of Aspirational Technology",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }