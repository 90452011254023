<template>
  <span></span>
</template>

<script>
import {generateBlocklyFunctionBody} from "@/blockly/blocks/common";
import {mapState} from "vuex";

export default {
  name: 'RemoteBlockRunner',
  props: {
    workspace: Object,
    remoteBlocksForTask: Array,
    remoteBlockMessage: Object,
    isPrimary: Boolean
  },
  data: function () {
    return {
      remoteBlockIds: {}
    }
  },
  computed: mapState(["hasActiveTask"]),
  watch: {
    hasActiveTask: function (newValue){
      if(newValue === false){
        this.remoteBlockIds = {}
      }
    },
    remoteBlocksForTask: {
      handler: 'handleRemoteBlocksForTask',
      immediate: true
    },
    remoteBlockMessage: {
      handler: 'handleRemoteBlockMessage'
    }
  },
  created() {
    this.$root.$on('generate-remote-block-code', this.generateCodeForBlock);

    // this.$root.$on('task-ended', function () {
    //   this.remoteBlockIds = {}
    // })
    this.$root.$on('student-id-changed', this.reportAllRemoteBlocks);
  },
  beforeDestroy() {
    this.$root.$off('generate-remote-block-code', this.generateCodeForBlock);
    // this.$root.$off('task-ended', function () {
    //   this.remoteBlockIds = {}
    // })
    this.$root.$off('student-id-changed', this.reportAllRemoteBlocks);
    this.workspace.removeChangeListener(this.onChange)
  },
  mounted() {
    this.$nextTick(function () {
          this.workspace.addChangeListener(this.onChange);
        }
    );
  },
  remoteBlockPrefix: 'procedures_def',
  methods: {
    handleRemoteBlocksForTask: function (blocks) {

      if (blocks) {
        let instance = this;
        this.$nextTick(function () {
          blocks.filter(block => block.deployToAll === true || (this.isPrimary ? block.deployToPrimary === true : false))
              .forEach(block => instance.addBlock(block.name, block.returnType));
        });

      }
    },
    handleRemoteBlockMessage: function (message) {
      let messageType = message.message;
      if (messageType === "addRemoteBlock") {
        this.addBlock(message.blockName, message.returnType, true);
      } else if (messageType === "removeRemoteBlock") {
        this.removeBlock(message.blockName);
      }
    },
    onLoadSavedRemoteBlocks: function (saved) {
      let remoteBlocks = saved.remoteBlocks;
      this.$nextTick(function () {
        remoteBlocks.forEach(block => {
          let match = this.workspace.getTopBlocks().find(candidate => candidate.getFieldValue("NAME") === block.name)
          this.remoteBlockIds[block.name] = match.id
        });
      });

    },
    reportRemoteBlock: function (blockName) {
      let msg = {
        message: "remoteBlockAdded",
        blockName: blockName
      }
      this.$root.$emit("send-student-message", msg)
    },
    reportAllRemoteBlocks: function () {
      let self = this;
      Object.keys(this.remoteBlockIds).forEach(name => self.reportRemoteBlock(name));
    },
    onChange: function (event) {

      this.$nextTick(function () {
        if (event.type === Blockly.Events.BLOCK_CREATE) {
          //note that remote blocks are function blocks so cannot be in stacks or hidden inside other blocks
          //so we don't need to traverse allt he children of the xml node looking for any hidden inside
          let type = event.xml.attributes["type"].value;
          if (type.startsWith(this.$options.remoteBlockPrefix)) {
            let block = this.workspace.getBlockById(event.blockId);
            //In the case of loading previous workspace, this might be null
            //because first a default block is loaded, then a new one is loaded. Both change
            //events will fire... but the first block will be gone by the time it fires
            if (block) {
              let blockName = this.workspace.getBlockById(event.blockId).getFieldValue("NAME");
              if (this.remoteBlocksForTask && this.remoteBlocksForTask.find(b => b.name === blockName)) {
                this.remoteBlockIds[blockName] = event.blockId;
                this.reportRemoteBlock(blockName);
              }
            }
          }
        }
      });
    },
    addBlock: function (blockName, returnType, sourceIsFromServer) {
      if (this.remoteBlockIds[blockName]) {
        Logger.warn(`Already have this remote block in workspace: ${blockName}`)
        if (sourceIsFromServer) {
          //Presumably the server believes this client does not have this block
          //so let's report that we do
          this.reportRemoteBlock(blockName);
        }
        //otherwise, it's just internal reloading of blocks and we can ignore

        //and either case, don't actually add it again!
        return
      }


      let alreadyInWorkspace = this.workspace.getTopBlocks().find(block=>block.getFieldValue && block.getFieldValue('NAME') === blockName);
      if(alreadyInWorkspace){
        //check it's correctly registered with client and server. Then that's all
        this.remoteBlockIds[blockName] = alreadyInWorkspace.id;
        this.reportRemoteBlock(blockName);
        return;
      }

      let dimensionsOfBlocksInWorkspace = this.workspace.getTopBlocks().map(block => block.getBoundingRectangle());
      let remote;
      if (returnType) {
        remote = this.workspace.newBlock("procedures_defreturn");
      } else {
        remote = this.workspace.newBlock("procedures_defnoreturn");
      }
      remote.setEditable(false);
      remote.setDeletable(false);
      remote.setFieldValue(blockName, "NAME");


      //Are there times when we do not want to automatically add remote blocks to the start?
      let startBlock = this.workspace.getTopBlocks().find(candidate => candidate.type === "start");

      let functionCall = this.workspace.newBlock("procedures_callnoreturn");
      functionCall.setFieldValue(blockName, "NAME");
      //for now, it's helpful to make this block deletable as it prevents students breaking the logic to run remote blocks locally!
      functionCall.setDeletable(false);
      functionCall.initSvg();
      functionCall.render();

      //if there is a start block, we need to connect the function call to it:
      if (startBlock) {
        let lastBlock = startBlock;
        while(lastBlock.getNextBlock()){
          lastBlock = lastBlock.getNextBlock();
        }
        functionCall.previousConnection.connect(lastBlock.nextConnection)
      }

      //position the definition below any existing blocks
      if (dimensionsOfBlocksInWorkspace) {

        let bottom = dimensionsOfBlocksInWorkspace.map(b => b.bottom).sort((a, b) => b - a)[0];
        let offset = (bottom ? bottom + 100 : 100);
        remote.moveTo(new Blockly.utils.Coordinate(10, offset));
      }

      remote.initSvg();
      remote.render();

    },
    removeBlock: function (blockName) {
      if (!this.remoteBlockIds[blockName]) {
        Logger.warn(`Remote block is not present to remove : ${blockName}`)
        return
      }

      let block = this.workspace.getBlockById(this.remoteBlockIds[blockName]);
      let childrenToRehome = block.getChildren();
      if (childrenToRehome.length > 0) {
        childrenToRehome[0].unplug();
      }
      this.remoteBlockIds[blockName] = null;
      block.dispose();

      let msg = {
        message: "remoteBlockRemoved",
        blockName: blockName
      }
      this.$root.$emit("send-student-message", msg)

    },
    generateCodeForBlock: function (details) {
      if (details.type === 'remoteBlock') {
        let code = null;
        let error = null;
        if (this.remoteBlockIds[details.blockName]) {

          let block = this.workspace.getBlockById(this.remoteBlockIds[details.blockName]);
          if (block) {
            Blockly.JavaScript.init(this.workspace);
            code = generateBlocklyFunctionBody(block, 'remoteBlock') + '\n remoteBlock();';
          } else {
            error = 'Could not find remote block. Was remote block id properly registered?';
            Logger.error(error);
          }

        } else {
          error = 'No remote block with name: ' + details.blockName
          Logger.error(error)
        }

        this.$root.$emit("remote-code-generated", {
          name: details.blockName,
          responseId: details.responseId,
          code: code,
          error: error
        });
      }

    }
  }
}
</script>
