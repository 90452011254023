<template>
  <div></div>

</template>

<script>
import $ from 'jquery'
//TODO reimpliment as a mixin
export default {
  name: 'WorkspaceSaver',
  props: {
    currentWorkspaceBase64: String,
    authCode: String,
    taskId: Number,
    studentId: String,
    studentName: String

  },
  data() {
    return {
      blocksLastSent: null
    }
  },
  mounted() {
    setInterval(this.postSaveWorkspaceMessage, 30000);
  },
  methods: {
    postSaveWorkspaceMessage: function(){
      if(this.studentId && this.currentWorkspaceBase64 && this.taskId && this.authCode && this.studentName){
        if(this.blocksLastSent !== this.currentWorkspaceBase64) {
          const workspaceToSend = {
            taskSessionId: this.taskId,
            studentName: this.studentName,
            workspaceBase64: this.currentWorkspaceBase64
          };
          $.ajax({
            url: `/api/student/${this.studentId}/workspace/`,
            type: 'POST',
            headers: {'Authorization': this.authCode},
            data: JSON.stringify(workspaceToSend),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
          }).done(() => {
            this.blocksLastSent = workspaceToSend.workspaceBase64;
            console.log("saved workspace")
          })
              .fail(jqXHR => Logger.error(JSON.stringify(jqXHR)))
        }
      }
    }
  }
}
</script>

