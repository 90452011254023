//import {HTTP} from '@/http-common'

export const userService = {
    login: getFacilitatorAuthKey,
    logout,
    authoriseWithSavedData,
};

async function authoriseWithSavedData() {
    const savedAuth = JSON.parse(localStorage.getItem("user"));
    //const millis = Date.now() - start;
    if(savedAuth){
        let hasExpired = Date.now() - savedAuth.saved > 7 * 3600000
        if(hasExpired){
            logout()
            return Promise.reject("Saved auth expired")
        }
    }
    if(savedAuth && savedAuth.authData) {
        try {
            //const [userName, password] = window.atob(authData).split(':')
            return internalGetAuthKey(savedAuth.authData);
        } catch {
            return Promise.reject("auth failed")
        }
    }
    else{
        return Promise.reject("No saved auth")
    }


}

function internalGetAuthKey(authData){
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Basic ${authData}`
        }
    }
    return fetch(`/api/facilitator/authKey`, requestOptions)
        .then(handleResponse)
}
function getFacilitatorAuthKey(username, password) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Basic ${window.btoa(username + ":" + password)}`
        }
    }
    let authData = window.btoa(username + ":" + password)
    return internalGetAuthKey(authData)
        .then(authKey => {
            // login successful if there's a user in the response
            if (authKey) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                const user = {
                    name: username,
                    authData: window.btoa(username + ":" + password),
                    saved: Date.now()
                }
                localStorage.setItem("user", JSON.stringify(user));
            }

            return authKey;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
}


function handleResponse(response) {
    return response.text().then(text => {
        const authKey = text;
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = response.statusText;
            return Promise.reject(error);
        }

        return authKey;
    });
}
