//Block definitions:
const {getCommandToSend, setupBlockWithInputAndDropdown, setupBlockNoArg} = require("@/blockly/blocks/common");
//these are set up with the new methods

setupBlockWithInputAndDropdown("arms_specific", "Move {dropdown}:  {String}",3000,
    {
      argName: "DESCRIPTION",
      style: "arm_blocks",
      inputType: "String",
      dropdownOptions: ["my left arm", "my right arm", "both arms"],
      tooltip: "Describe how LeX should move her arm. Be precise or LeX might not understand."
    } )

setupBlockWithInputAndDropdown("arms_placeobject", "Put the object I am holding {String}",3000,
    {
        argName: "WHERE",
        style: "arm_blocks",
        inputType: "String"
    } )

setupBlockNoArg("arms_removelid", "Remove pen lid", 1000, {style: "arm_blocks"});
setupBlockNoArg("arms_putonlid", "Put on pen lid", 1000, {style: "arm_blocks"});

// setupBlockDropdownArgument("pickup_objectdropdown", "Pick up {}",["mug", "paper", "box", "pen", "book"], 2000,
//     {
//         style: "arm_blocks"
//     } )




//these are manually created

  Blockly.Blocks['arms_raise'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Raise ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left arm","left arm"], ["right arm","right arm"], ["both arms","both arms"]]), "arm")
          .appendField(" by");
      this.appendValueInput("DEGREES")
          .setCheck("Number")
      this.appendDummyInput()
          .appendField(" degrees")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['arms_lower'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Lower ");
      this.appendDummyInput()      
      .appendField(new Blockly.FieldDropdown([["left arm","left arm"], ["right arm","right arm"], ["both arms","both arms"]]), "arm")
          .appendField("arm by");
      this.appendValueInput("DEGREES")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField(" degrees")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['elbow_bend'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Bend ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "elbow")
          .appendField("elbow by");
      this.appendValueInput("DEGREES")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField(" degrees")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['elbow_straighten'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Straighten ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "elbow")
          .appendField("elbow by");
      this.appendValueInput("DEGREES")
          .setCheck("Number")
      this.appendDummyInput()
          .appendField(" degrees")                      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };



  Blockly.Blocks['arms_pickup'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Pick up nearest object in my reach");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['arms_pickup_specific'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Pick up ");
      this.setInputsInline(true);
      this.appendDummyInput()          
          .appendField(new Blockly.FieldTextInput('an object'),
              'object');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['arms_drop'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Drop object");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['arms_putdown'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Put down object");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['hands_clap'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Clap hands");
      this.appendValueInput("TIMES")
          .setCheck("Number");
      this.appendDummyInput()
            .appendField("times");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['hands_click'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Click with my");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "HAND")
          .appendField("hand");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['arms_point'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("point with my");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "HAND")
          .appendField("hand");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['arms_pointat'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("point at");
      this.appendValueInput("TARGET")        
      this.appendDummyInput()
          .appendField(" with my");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "HAND")
          .appendField("hand");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("arm_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  


  
  


//Generators:


  
Blockly.JavaScript['arms_raise'] = function(block) {
    let value_degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC);
  
    let dropdown_arm = block.getFieldValue('arm');
    
    return getCommandToSend('"Raise " + "'+dropdown_arm+'" + " by " + ' +value_degrees+' + " degrees"', 1500);

  };

  Blockly.JavaScript['arms_lower'] = function(block) {
    let value_degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC);
  
    let dropdown_arm = block.getFieldValue('arm');
    
    return getCommandToSend('"Lower " + "'+dropdown_arm+'" + " by " + ' +value_degrees+'+" degrees"', 1500);    

  };
  
  Blockly.JavaScript['elbow_bend'] = function(block) {
    let value_degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC);
    let dropdown_arm = block.getFieldValue('elbow');
    
    return getCommandToSend('"Bend your " + "'+dropdown_arm+'" + " elbow by " + ' +value_degrees+' + " degrees"', 1500);

  };

  Blockly.JavaScript['elbow_straighten'] = function(block) {
    let value_degrees = Blockly.JavaScript.valueToCode(block, 'DEGREES', Blockly.JavaScript.ORDER_ATOMIC);
    let dropdown_arm = block.getFieldValue('elbow');
    
    return getCommandToSend('"Straighten your " + "'+dropdown_arm+'" + " elbow by " + ' +value_degrees+' + " degrees"', 1500);

  };
  

  Blockly.JavaScript['arms_pickup'] = function() {
    return getCommandToSend('"Pick up nearest object in your reach"', 3000, 'pickupObject');

  };

  Blockly.JavaScript['arms_pickup_specific'] = function(block) {    
    let object = block.getFieldValue('object');
    return getCommandToSend('"Pick up " + "' + object + '"', 3000, 'pickupSpecific', object);

  };

  Blockly.JavaScript['arms_drop'] = function() {
    return getCommandToSend('"Drop object"', 2000, 'dropObject');

  };
  
 
  Blockly.JavaScript['arms_putdown'] = function() {
    return getCommandToSend('"Put down object"', 3000, 'putDownObject');

  };
  
  Blockly.JavaScript['hands_clap'] = function(block) {
    let value_times = Blockly.JavaScript.valueToCode(block, 'TIMES', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    
    return getCommandToSend('"Clap your hands " + "'+value_times+'" + " times"', 1500+500* value_times);

  };

  Blockly.JavaScript['hands_click'] = function(block) {    
    let dropdown_hand = block.getFieldValue('HAND');    
    return getCommandToSend('"Click with your " + "'+dropdown_hand+'" + " hand"', 3000);

  };




  Blockly.JavaScript['arms_point'] = function(block) {    
    let dropdown_hand = block.getFieldValue('HAND');    
    return getCommandToSend(`'Point with your ${dropdown_hand} hand'`, 2000);

  };

  Blockly.JavaScript['arms_pointat'] = function(block) {    
    let dropdown_hand = block.getFieldValue('HAND');    
    let target = Blockly.JavaScript.valueToCode(block, 'TARGET', Blockly.JavaScript.ORDER_ATOMIC) || "";
    return getCommandToSend(`'Point at ' + ${target} + ' with your ${dropdown_hand} hand'`, 2500);
    
    
    

  };

