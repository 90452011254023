import $ from 'jquery'
export const InterpreterApi = {
    setCallbacks,
    setupApi: initInterpreterApi

}
let callbacks = {}

    function setCallbacks(getAuth, highlight, abort, startRemote, endRemote) {
        callbacks.getAuth = getAuth;
        callbacks.highlight = highlight;
        callbacks.abort = abort;
        callbacks.startRemote = startRemote;
        callbacks.endRemote = endRemote;
    }

    function handleFail(jqXHR) {
        console.log('failed')
        console.log(JSON.stringify(jqXHR))
        let error = {
            code: jqXHR.status,
            message: jqXHR.responseText
            //can add a human message here if ever applicable
        }
        if (jqXHR.status === 400 || jqXHR.status === 403) {
            Logger.error('bad request - probably an auth error')
        }
        if (jqXHR.status === 404) {
            Logger.error('not found - probably student disconnected')
        }
        else if (jqXHR.status === 500) {
            Logger.error('error from lex');
        }
        else if(jqXHR.status === 503) {
            Logger.error('503 error - probably an async request timeout. Continuing')
        }
        else {
            Logger.error('unknown status ' + jqXHR.status);
        }
        //we will ignore 503s as they are likely just async requests timing out rather than proper failures
        if(jqXHR.status !== 503){
            callbacks.abort(error);
        }

    }


    function initInterpreterApi(interpreter, scope) {
        console.log("initialising API");
        if (!callbacks.getAuth || !callbacks.highlight || !callbacks.abort) {
            Logger.error("Cannot init interpreter without the callbacks")
            return;
        }

        //Add API function for sending commands
        let sendCommandWrapper = interpreter.createAsyncFunction(
            function (textCommand, timeout, displayImage, simCommandName, commandArgs, callback) {

                if (callbacks.getAuth()) {
                    //we have auth to  connect to Lex. Send to server.
                    console.log('sending: ' + textCommand);
                    let imageParam = displayImage ? `&displayImage=${displayImage}` : ``
                    $.ajax({
                        url: `/api/broadcast/sendCommand?content=${encodeURI(textCommand)}&timeout=${timeout}${imageParam}`,
                        type: 'PUT',
                        headers: {'Authorization': callbacks.getAuth()}
                    })
                        .done(function (data, textStatus, jqXHR) {
                            console.log('received response')
                            console.log(JSON.stringify(jqXHR))
                            if (jqXHR.status === 204 || jqXHR.jqXHR === 200) {
                                callback();
                            }

                        })
                        .fail(handleFail);
                } else {
                    //not authorised for lex. Run on simulator
                    let simulator = document.getElementById('simulator');
                    if (simulator && simCommandName) {
                        simulator.contentWindow.handleCommand({name: simCommandName, argument: commandArgs})
                            .then(
                                () => {
                                    console.log("command completed im simulator...")
                                    callback();
                                }
                            );
                    }

                }
            }
        )

        interpreter.setProperty(scope, 'sendCommand', sendCommandWrapper);

        //Add API function for sending queries
        let sendQueryWrapper = interpreter.createAsyncFunction(
            function (queryToSend, responseType, callback) {

                if (callbacks.getAuth()) {
                    console.log('sending query: ' + queryToSend);
                    $.ajax(
                        {
                            url: '/api/broadcast/sendQuery?content=' + queryToSend + '&responseType=' + responseType,
                            type: 'PUT',
                            headers: {'Authorization': callbacks.getAuth()}
                        })
                        .done(function (data, textStatus, jqXHR) {

                            //TODO: read response code. Handle error etc
                            //TODO: Do we want to be responding with json?
                            if (jqXHR.status === 200) {

                                if (responseType === "boolean") {
                                    callback((jqXHR.responseText === "true"));
                                } else if (responseType === "number") {
                                    let number = parseInt(jqXHR.responseText)
                                    if (isNaN(number)) {
                                        Logger.error("Not a number: " + number);
                                        callbacks.abort(number)
                                    }
                                    callback(number)
                                } else {
                                    callback(jqXHR.responseText);
                                }

                            }
                        })
                        .fail(handleFail);

                }
            }
        )

        interpreter.setProperty(scope, 'sendQuery', sendQueryWrapper);

        //add api function for block highlighting:
        let highlightWrapper = function (id) {
            id = String(id || '');
            return callbacks.highlight(id);
        }
        interpreter.setProperty(scope, 'highlightBlock', interpreter.createNativeFunction(highlightWrapper));


        let executeRemoteWrapper = interpreter.createAsyncFunction(
            function (remoteBlockName, studentId, commandArgs, callback) {

                if (callbacks.getAuth()) {

                    //introduce a short delay to make it claerer for the viewer what is going on
                    new Promise(resolve => setTimeout(resolve, 1000))
                        .then(
                            ()=> {
                                //inform the UI that the remote execution as started
                                callbacks.startRemote(studentId);
                                //and make the request on the server
                                $.ajax({
                                    url: `/api/student/${studentId}/execute/${remoteBlockName}?param=${commandArgs}`,
                                    type: 'PUT',
                                    headers: {'Authorization': callbacks.getAuth()}
                                })
                                    .done(function (data, textStatus, jqXHR) {

                                        if (jqXHR.status === 204) {
                                            callback();
                                            callbacks.endRemote(studentId);
                                        }
                                        else if(jqXHR.status == 200){
                                            callback(jqXHR.responseText);
                                            callbacks.endRemote(studentId);
                                        }
                                        //TODO: We are currently always stopping execution on remote failure. Is this what we want?
                                    })
                                    .fail(function (jqXHR, textStatus, errorThrown) {
                                        handleFail(jqXHR, textStatus, errorThrown);
                                        callbacks.endRemote(studentId);
                                    });
                            }
                        )



                }
            }
        )

        interpreter.setProperty(scope, 'executeRemoteBlock', executeRemoteWrapper);

    }




