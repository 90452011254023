export const LEGS_CATEGORY_XML=`<category name="Legs" categorystyle="legs_category" iconclass="blocklyTreeIconCustom legs" css-container="blocklyToolboxCategory legs">
        <label text="Legs" web-class="blocklyFlyoutHeading"></label>`
export const RAISE_LEGS_XML=`<block type="legs_raise">
            <value name="degrees">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value></block>`
export const LOWER_LEGS_XML=`<block type="legs_lower">
            <value name="degrees">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
</block>`
export const BEND_KNEES_XML=
    `<block type="knees_bend">
        <value name="degrees">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
        </value>
</block>`
export const STRAIGHTEN_KNEES_XML=`<block type="knees_straighten">
        <value name="degrees">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
</block>`
export const KICK_XML=`<block type="legs_kick"></block>`
export const SIDE_KICK_XML=`<block type="legs_sidekick"></block>`
export const STEP_XML=`<block type="legs_step"></block>`
export const SPECIFICS_LEGS_XML=`<block type="legs_specific">
    <value name="DESCRIPTION">
        <shadow type="text">
            <field name="TEXT">wiggle my knees to the left then to the right</field>
        </shadow>
    </value>
</block>`