const {getCommandToSend, getQueryToSend} = require("@/blockly/blocks/common");
//Block definitions:


Blockly.Blocks['move_isobstacle'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Is there an obstacle ")
            .appendField(new Blockly.FieldDropdown([["in front of me","in front of me"], ["to my right","to my right"], ["to my left","to my left"], ["behind me","behind me"]]), "DIRECTION")
            .appendField("?");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setStyle("sensing_blocks");
        this.setTooltip("Test if there is an obstacle in LeX's way");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['arms_amiholding'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Am I holding");
        this.appendValueInput("OBJECT")
        this.appendDummyInput()
            .appendField("?");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setStyle("sensing_blocks");
        this.setTooltip("Test what LeX is holding");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['object_aremoreobjects'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Are there more objects?");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setStyle("sensing_blocks");
        this.setTooltip("Test what LeX is holding");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['object_isobject'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is the object I am holding");
        this.appendValueInput("QUALITY")
        this.appendDummyInput()
            .appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['object_isobjecttexture'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is the texture of the object");        
        this.appendValueInput("TEXTURE")        
          .appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test the texture of an object");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['object_isobjectcontents'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Does the object contain");
          this.appendValueInput("CONTENTS")        
          .appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test what the object contains");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['object_isobjectsize'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is the size if the object");
          this.appendValueInput("SIZE")        
          .appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test what the object contains");
   this.setHelpUrl("");
    }
  };
Blockly.Blocks['sense_aminear'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("Am I near");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["a child","a child"], ["a hiding spot","a hiding spot"], ["a desk","a desk"],
                ["a window","a window"], ["a teacher", "a teacher"], ["a book", "a book"], ["some litter", "some litter"]]), "TARGET")
        this.appendDummyInput()
            .appendField("?");
        this.setInputsInline(true);
        this.setOutput(true, "Boolean");
        this.setStyle("sensing_blocks");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['wait_waitseconds'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Wait for");
      this.appendValueInput("SECONDS")
          .setCheck("Number")          
      this.appendDummyInput()                
          .appendField("seconds");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("event_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['wait_waitwhileuntil'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Wait ")
          .appendField(new Blockly.FieldDropdown([["while","while"], ["until","until"]]), "WHILE_UNTIL")          
      this.appendValueInput("CONDITION")
          .setCheck("Boolean");          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("event_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['sense_ismoving'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Did you see");
      this.appendValueInput("TARGET")        
      this.appendDummyInput()
          .appendField(" moving?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if LeX saw something moving");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['sense_islooking'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is");
      this.appendValueInput("TARGET")        
      this.appendDummyInput().
          appendField("looking at me?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if something is looking at me");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['sense_istouching'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is");
      this.appendValueInput("SUBJECT")        
      this.appendDummyInput().
          appendField("touching");
      this.appendValueInput("OBJECT")        
      this.appendDummyInput().
          appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if two things are touching");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['sense_didxseeymove'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Did");
      this.appendValueInput("SUBJECT")        
      this.appendDummyInput().
          appendField("see");
      this.appendValueInput("OBJECT")        
      this.appendDummyInput().
          appendField("move?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if something is looking at me");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['sense_ismakingnoise'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is");
      this.appendValueInput("SUBJECT")        
        .setCheck("String");        
      this.appendDummyInput()
        .appendField("making a noise?");      
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if something is making a noise");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['sense_isinposition'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is");
      this.appendValueInput("SUBJECT") 
        .setCheck("String");        
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["on a chair","on a chair"], ["standing up","standing up"],
        ["on the floor","on the floor"], ["on the desk","on the desk"]]), "POSITION");      
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setStyle("sensing_blocks");
   this.setTooltip("Test if what position something is in making a noise");
   this.setHelpUrl("");
    }
  };

  

  //GENERATORS:
// eslint-disable-next-line
  Blockly.JavaScript['object_aremoreobjects'] = function() {
          
    let code = getQueryToSend('"Are there more objects?"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

Blockly.JavaScript['object_isobject'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'QUALITY', Blockly.JavaScript.ORDER_ATOMIC) || "";

    let code = getQueryToSend('"Is the object I am holding " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

  Blockly.JavaScript['object_isobjecttexture'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'TEXTURE', Blockly.JavaScript.ORDER_ATOMIC) || "";
          
    let code = getQueryToSend('"Is the texture of the object " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };
  
  Blockly.JavaScript['object_isobjectcontents'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'CONTENTS', Blockly.JavaScript.ORDER_ATOMIC) || "";
          
    let code = getQueryToSend('"Does the object contain " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['object_isobjectsize'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'SIZE', Blockly.JavaScript.ORDER_ATOMIC) || "";
          
    let code = getQueryToSend('"Is the size of the object " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };
  Blockly.JavaScript['sense_aminear'] = function(block) {
      let dropdown_target = block.getFieldValue('TARGET');

        let code = getQueryToSend('"Am I near " + "' + dropdown_target + '?"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['wait_waitseconds'] = function(block) {
    let value_wait = Blockly.JavaScript.valueToCode(block, 'SECONDS', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    return getCommandToSend('"Wait"', 1000 * value_wait);

  };

  Blockly.JavaScript['wait_waitwhileuntil'] = function(block) {
    let until = block.getFieldValue('WHILE_UNTIL')
    let argument0 = Blockly.JavaScript.valueToCode(block, 'CONDITION',
      until === 'until' ? Blockly.JavaScript.ORDER_LOGICAL_NOT :
      Blockly.JavaScript.ORDER_NONE) || 'false';
  
    if (until === 'until') {
      argument0 = '!' + argument0;
    }
    //this is a naive first implementation. It may work badly on simulator!
    return `
    sendCommand('Please wait ${until} the following is true', 50);
    while (${argument0}) {}\n`;
    

  };

  Blockly.JavaScript['sense_ismoving'] = function(block) {
    let target = Blockly.JavaScript.valueToCode(block, 'TARGET', Blockly.JavaScript.ORDER_ATOMIC) || '';
    
    let code = getQueryToSend(`'Was '+${target}+' moving?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  
  Blockly.JavaScript['sense_islooking'] = function(block) {
    let target = Blockly.JavaScript.valueToCode(block, 'TARGET', Blockly.JavaScript.ORDER_ATOMIC) || '';
    
    let code = getQueryToSend(`'Is '+${target}+' looking at me?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['sense_istouching'] = function(block) {
    let subject = Blockly.JavaScript.valueToCode(block, 'SUBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    let object = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    
    let code = getQueryToSend(`'Is ' + ${subject}+' touching ' + ${object} + '?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['sense_didxseeymove'] = function(block) {
    let subject = Blockly.JavaScript.valueToCode(block, 'SUBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    let object = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    
    let code = getQueryToSend(`'Did ' + ${subject}+' see ' + ${object} + ' move?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['sense_ismakingnoise'] = function(block) {
    let subject = Blockly.JavaScript.valueToCode(block, 'SUBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    
    let code = getQueryToSend(`'Is '+${subject}+' making noise?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['sense_isinposition'] = function(block) {
    let subject = Blockly.JavaScript.valueToCode(block, 'SUBJECT', Blockly.JavaScript.ORDER_ATOMIC) || '';
    var position = block.getFieldValue('POSITION');    
    let code = getQueryToSend(`'Is '+${subject}+' ${position}?'`);
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

Blockly.JavaScript['arms_amiholding'] = function(block) {
    let value_text = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_ATOMIC) || "";

    let code = getQueryToSend('"Am I holding " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['move_isobstacle'] = function(block) {
    let dropdown_direction = block.getFieldValue('DIRECTION');

    let code = getQueryToSend('"Is there an obstacle " + "' + dropdown_direction + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};