export const engineerMessages = {
    workspaceUpdate,
    currentWorkspace,
    highlightBlock,
    updateStudentData,
    requestAuthoriseTeacher,
    restorePreviousSession
}
function workspaceUpdate(eventAsJson){
    return {
        'message': 'workspaceEvent',
        'content': btoa(JSON.stringify(eventAsJson))
      };
}

function currentWorkspace(workspaceAsBase64){
    return {
        'message': 'currentWorkspace',
        'base64': workspaceAsBase64
    }
}

function highlightBlock(id){
    return {
        'message': 'highlightBlock',
        'blockId': id
    }
}

function updateStudentData(name, previousPrimaryAuthKey){
    if(!name){
        throw "tried to send updateStudentData with no name";
    }

    let message =  {
        'message': 'updateStudentData',
        'name' : name
      };
    if(previousPrimaryAuthKey){
        message.previousAuthKey = previousPrimaryAuthKey
    }
    return message
}

function requestAuthoriseTeacher(){
    return {
        'message': 'requestAuthoriseTeacher'
    };
}

function restorePreviousSession(oldId, name, previousAuthKey){
    let message =
     {
        message: "restorePreviousSession",
        studentName: name,
        previousId: oldId
    }
    if(previousAuthKey){
        message.previousAuthKey = previousAuthKey
    }
    return message
}