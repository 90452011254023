export const SPEECH_CATEGORY_XML=`<category name="Speech" categorystyle="speech_category" iconclass="blocklyTreeIconCustom speech" css-container="blocklyToolboxCategory speech">
    <label text="Speech" web-class="blocklyFlyoutHeading"></label>`
export const SPEECH_SAY_XML=`<block type="speech_say">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const SPEECH_WRITE_AND_SAY_XML=`<block type="speech_writeandsay">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const SPEECH_WHISPER_XML=`<block type="speech_whisper">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const SPEECH_ASK_INPUT_XML=`<block type="speech_askforinput">
        <value name="INPUT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const SPEECH_ASK_NUMBER_XML=`<block type="speech_askfornumber">
        <value name="NUMBER">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`