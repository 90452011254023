export const SENSING_CATEGORY_XML=`<category name="Sensing" categorystyle="sensing_category" iconclass="blocklyTreeIconCustom sensing" css-container="blocklyToolboxCategory sensing">
    <label text="Sensing" web-class="blocklyFlyoutHeading"></label>`

export const SENSE_IS_OBSTALCE_XML=`<block type="move_isobstacle"></block>`
export const SENSE_AM_I_HOLDING_XML=`<block type="arms_amiholding">
        <value name="OBJECT">
            <shadow type="text">
                <field name="OBJECT"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_OBJECT_XML=`<block type="object_isobject">
        <value name="QUALITY">
            <shadow type="text">
                <field name="TEXT">round</field>
            </shadow>
        </value>
    </block>`
export const SENSE_ARE_MORE_OBJECTS=`<block type="object_aremoreobjects">        
    </block>`
export const SENSE_IS_OBJECT_CONTENTS_XML=`<block type="object_isobjectcontents">
        <value name="CONTENTS">
            <shadow type="text">
                <field name="CONTENTS"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_OBJECT_SIZE_XML=`<block type="object_isobjectsize">
        <value name="SIZE">
            <shadow type="text">
                <field name="SIZE"></field>
            </shadow>
        </value>
    </block>`

export const SENSE_IS_MOVING_XML=`<block type="sense_ismoving">
        <value name="TARGET">
            <shadow type="text">
                <field name="TARGET"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_LOOKING_XML=`<block type="sense_islooking">
        <value name="TARGET">
            <shadow type="text">
                <field name="TARGET"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_MAKING_NOISE_XML=`<block type="sense_ismakingnoise">
        <value name="SUBJECT">
            <shadow type="text">
                <field name="SUBJECT"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_IN_POSITION_XML=`<block type="sense_isinposition">
        <value name="SUBJECT">
            <shadow type="text">
                <field name="SUBJECT"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_IS_TOUCHING_XML=`<block type="sense_istouching">
        <value name="SUBJECT">
            <shadow type="text">
                <field name="SUBJECT"></field>
            </shadow>
        </value>
        <value name="OBJECT">
            <shadow type="text">
                <field name="OBJECT"></field>
            </shadow>
        </value>
    </block>`
export const SENSE_DID_X_SEE_Y_MOVE_XML=`<block type="sense_didxseeymove">
        <value name="SUBJECT">
            <shadow type="text">
                <field name="SUBJECT"></field>
            </shadow>
        </value>
        <value name="OBJECT">
            <shadow type="text">
                <field name="OBJECT"></field>
            </shadow>
        </value>
    </block>`

export const SENSE_AM_I_NEAR=`<block type="sense_aminear">        
    </block>`