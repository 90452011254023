var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer-wrapper", attrs: { id: "serverListener" } },
    [
      _c(
        "div",
        { staticClass: "container box" },
        [
          _c(
            "div",
            { staticClass: "section header" },
            [
              _c("engineer-login", {
                attrs: {
                  "in-overview": _vm.inOverview,
                  "current-workspace": _vm.blocksBase64,
                  "student-id": _vm.studentId,
                },
                on: {
                  "login-submitted": _vm.loginSubmitted,
                  "forget-previous-login": function ($event) {
                    _vm.studentName = ""
                  },
                },
              }),
              _c("active-task-viewer", {
                attrs: {
                  "is-primary-user": false,
                  "current-task-message": _vm.currentTaskMessage,
                },
              }),
              _vm.hasActiveTask === true && _vm.inOverview !== true
                ? _c(
                    "div",
                    [
                      _vm.connectedToServer === false
                        ? _c("div", { staticClass: "warning" }, [
                            _vm._v("Please check your internet connection"),
                          ])
                        : _vm._e(),
                      _c("lex-runner", {
                        attrs: {
                          "auth-code": _vm.lexAuthCode,
                          "reset-button-name": "Reset",
                          "current-task-message": _vm.currentTaskMessage,
                          "code-to-execute": _vm.codeToExecute,
                          "remote-block-message": _vm.remoteBlockMessage,
                        },
                        on: {
                          "remote-execution-complete": _vm.sendStudentMessage,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("workspace-saver", {
                attrs: {
                  "auth-code": "notImplemented",
                  "task-id": _vm.activeTask.sessionId,
                  "student-name": _vm.studentName,
                  "student-id": _vm.studentId,
                  "current-workspace-base64": _vm.blocksBase64,
                },
              }),
            ],
            1
          ),
          _vm.hasActiveTask === true && _vm.inOverview === false
            ? _c("blockly-component", {
                attrs: {
                  "current-task-message": _vm.currentTaskMessage,
                  "send-event-jsons": _vm.mirrorCodeToServer,
                  "send-base64-updates": true,
                  "remote-block-message": _vm.remoteBlockMessage,
                },
                on: {
                  "code-updated": function ($event) {
                    _vm.codeToExecute = $event
                  },
                  "event-json-updated": _vm.sendWorkspaceUpdateMessage,
                  "base64-updated": function ($event) {
                    _vm.blocksBase64 = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }