<template>
  <span></span>
</template>

<script>
import {generateBlocklyFunctionBody} from "@/blockly/blocks/common";

export default {
  name: 'LexEventHandler',
  props: {
    workspace: Object,
    // lexEventsForTask: Array,
    eventTriggerMessage: Object,
    readOnly: Boolean
  },
  data: function () {
    return {
      availableLexEvents: [],
      //handledLexEvents: [],
      eventHandlerIds: {}
    }
  },
  computed: {
    lexEventsForTask (){
      return this.$store.state.eventsForTask;
    }
  },
  watch: {
    eventTriggerMessage: {
      handler: 'handleEventTriggerMessage'
    },
    lexEventsForTask: {
      handler: 'updateLexEvents',
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(function () {
          this.workspace.addChangeListener(this.onChange);
        }
    );
    this.availableLexEvents = this.lexEventsForTask;
    this.defineEventHandlerBlock();
  },
  methods: {
    updateLexEvents: function () {
      this.eventHandlerIds = {};
      this.defineEventHandlerBlock();
      this.updateAvailableLexEvents();
    },
    handleEventTriggerMessage: function (message) {
      let messageType = message.message;
      if (messageType === "triggerLexEvent") {
        this.generateCodeForEvent(message);
      }
    },
    onChange: function (event) {
      if (this.lexEventsForTask && this.lexEventsForTask.length > 0) {


        this.$nextTick(function () {
          if (event.type === Blockly.Events.BLOCK_CREATE) {
            //note that event handlers cannot be in other blocks or stacks
            //so we don't need to iterate through the xml looking for any others hidden inside
            let type = event.xml.attributes["type"].value;
            if (type === "events_handler") {
              let block = this.workspace.getBlockById(event.blockId);
              if (block) {
                let eventName = block.getFieldValue('EVENT');
                if (eventName !== "") {
                  this.eventHandlerIds[eventName] = event.blockId;
                  this.updateAvailableLexEvents();
                }

              }
            }
          } else if (event.type === Blockly.Events.BLOCK_DELETE) {
            let type = event.oldXml.attributes["type"].value;
            if (type === "events_handler") {
              let entries = Object.entries(this.eventHandlerIds)
              if (entries && entries.length > 0) {
                let eventName = entries.find(value => value[1] === event.blockId)[0];
                if (eventName) {
                  delete this.eventHandlerIds[eventName];
                  this.updateAvailableLexEvents();
                }
              }
            }

          }
        });
      }
    },
    generateCodeForEvent: function (details) {

      let code = null;
      let error = null;
      if (this.eventHandlerIds[details.eventName]) {

        let block = this.workspace.getBlockById(this.eventHandlerIds[details.eventName]);
        if (block) {
          //code = this.getFunctionName(details.eventName, block.id) + "();"
          Blockly.JavaScript.init(this.workspace);
          //
          code = generateBlocklyFunctionBody(block, 'handleEvent') + '\n handleEvent();';
          this.$root.$emit("event-code-generated", {
            name: details.eventName,
            code: code
          });
        } else {
          error = 'Could not find event handler block. Was block id properly registered?';
          Logger.error(error);
        }

      }


    },
    updateAvailableLexEvents: function () {

      if (this.lexEventsForTask) {
        if(this.readOnly){
          this.availableLexEvents = this.lexEventsForTask;
        }
        else {
          let eventsInWorkspace = Object.keys(this.eventHandlerIds);
          this.availableLexEvents = this.lexEventsForTask.filter(name => !eventsInWorkspace.includes(name));
        }
      } else {
        this.availableLexEvents = []
      }
    },
    getEventsDropDownOptionsSupplier: function () {
      let instance = this;
      return function () {
        let options = instance.availableLexEvents ? instance.availableLexEvents.map(x => [x, x]) : [];
        options.splice(0, 0, ['Choose an event', '']);
        return options;
      }
    },
    getEventsDropDownOptionsValidationSupplier: function () {
      let instance = this;
      return function (value) {

        //we don't want to allow users to re-select the default option:
        if (value == "") {
          return null;
        }
        if (instance.availableLexEvents && instance.availableLexEvents.includes(value)) {
          let oldValue = this.value_;
          let blockId = this.sourceBlock_.id;
          instance.eventHandlerIds[value] = blockId;
          delete instance.eventHandlerIds[oldValue];
          instance.updateAvailableLexEvents();
          return value;
        }
        return "";
      }
    },
    defineEventHandlerBlock: function () {
      let instance = this;
      // if (Blockly.Blocks["events_handler"]) {
      //     return;
      // }
      Blockly.Blocks["events_handler"] = {
        init: function () {
          this.appendDummyInput()
              .appendField("When ")
              .appendField(new Blockly.FieldDropdown(instance.getEventsDropDownOptionsSupplier(),
                      instance.getEventsDropDownOptionsValidationSupplier())

                  , 'EVENT');

          this.appendStatementInput("STACK")
          this.setInputsInline(true);
          this.setPreviousStatement(false, null);
          this.setNextStatement(false, null);
          this.setStyle("event_blocks");
          this.setTooltip("");
          this.setHelpUrl("");
        }
      };

      Blockly.JavaScript["events_handler"] = function () {
        //let eventName = block.getFieldValue('EVENT');
        //let functionName = instance.getFunctionName(eventName, block);
        //Blockly.JavaScript.init(this.workspace);

        //let code = generateBlocklyFunctionBody(block, functionName);
        //return code;
        return "";
      }
    },
    getFunctionName: function (eventName, block) {
      return 'handle' + eventName + block.id
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
