export const MOVEMENT_CATEGORY_XML=`<category name="Movement" categorystyle="movement_category" iconclass="blocklyTreeIconCustom movement"  css-container="blocklyToolboxCategory movement">
    <label text="Movement" web-class="blocklyFlyoutHeading"></label>`

export const MOVEMENT_WALK_FORWARD_XML=`
    <block type="move_walkforward">
        <value name="STEPS">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const MOVEMENT_WALK_BACKWARDS_XML=`<block type="move_walkbackwards">
        <value name="STEPS">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const MOVEMENT_STEP_SIDEWAYS_XML=`    
    <block type="move_stepsideways">
        <value name="STEPS">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const MOVEMENT_TURN_XML=`
    <block type="move_turn">
        <value name="DEGREES">
            <shadow type="math_number">
                <field name="NUM">90</field>
            </shadow>
        </value>
    </block>`
export const MOVEMENT_CROUCH_XML=`<block type="move_crouch"></block>`
export const MOVEMENT_STOP_XML=`<block type="move_stop"></block>`
export const MOVEMENT_JUMP_XML=`<block type="move_jump"></block>`
export const MOVEMENT_SIT_XML=`<block type="move_sit"></block>`
export const MOVEMENT_STAND_XML=`<block type="move_stand"></block>`
export const MOVEMENT_HOP_XML=`<block type="move_hop"></block>`
export const MOVEMENT_RETRACE_STEPS_XML=`<block type="move_retracesteps"></block>`
export const MOVEMENT_CRAWL_FORWARDS_XML=`<block type="move_crawlforward">
        <value name="STEPS">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const MOVEMENT_CRAWL_BACKWARDS_XML=`<block type="move_crawlbackward">
        <value name="STEPS">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`

export const MOVEMENT_WALK_TOWARDS_GRANDMA_XML=`<block type="move_walktowardsgrandma"></block>`
export const MOVEMENT_WALK_TOWARDS_OPTION_XML=`<block type="move_walktowardsoption"></block>`
