<template>
  <div>
  </div>
</template>

<script>
//maybe refactor not as a component but a mixin?
import '@/blockly/blocks/arms'
import '@/blockly/blocks/drawing'
import '@/blockly/blocks/legs'
import '@/blockly/blocks/movement'
import '@/blockly/blocks/sensing'
import '@/blockly/blocks/speech'
import '@/blockly/blocks/storytelling'
import {
  BEND_KNEES_XML, KICK_XML,
  LEGS_CATEGORY_XML,
  LOWER_LEGS_XML, RAISE_LEGS_XML, SIDE_KICK_XML, SPECIFICS_LEGS_XML,
  STEP_XML,
  STRAIGHTEN_KNEES_XML
} from "@/toolbox/categories/Legs";
import {FLOW_STATEMENTS_XML, FOR_EACH_XML, LOOPS_XML, REPEAT_XML, WHILE_UNTIL_XML} from "@/toolbox/categories/Loops";
import {
  EVENTS_CATEGORY_XML,
  EVENTS_WAIT_SECONDS_XML,
  EVENTS_WAIT_WHILE_UNTIL_XML,
  EVENTS_WHEN_XML
} from "@/toolbox/categories/Events";
import {
  ARMS_CATEGORY_XML,
  BEND_ELBOW_XML,
  CLAP_XML,
  CLICK_XML,
  DROP_XML,
  LOWER_ARMS_XML,
  PICKUP_SPECIFIC_XML,
  PICKUP_XML,
  PLACE_OBJECT_XML,
  POINT_AT_XML,
  POINT_XML,
  PUT_DOWN_XML,
  PUT_ON_PEN_LID_XML,
  RAISE_ARMS_XML,
  REMOVE_PEN_LID_XML,
  SPECIFIC_ARMS_XML,
  STRAIGHTEN_ELBOW_XML
} from "@/toolbox/categories/Arms";
import {
  BOOLEAN_XML,
  COMPARE_XML,
  IF_XML,
  LOGIC_XML,
  NEGATE_XML,
  OPERATION_XML,
  TERNARY_XML
} from "@/toolbox/categories/Logic";
import {ARITHMETIC_XML, MATH_CATEGORY_XML, NUMBER_XML, RANDOM_NUMBER_XML, SINGLE_XML} from "@/toolbox/categories/Math";
import {
  MOVEMENT_CATEGORY_XML, MOVEMENT_CRAWL_BACKWARDS_XML, MOVEMENT_CRAWL_FORWARDS_XML,
  MOVEMENT_CROUCH_XML, MOVEMENT_HOP_XML, MOVEMENT_JUMP_XML,
  MOVEMENT_RETRACE_STEPS_XML, MOVEMENT_SIT_XML, MOVEMENT_STAND_XML,
  MOVEMENT_STEP_SIDEWAYS_XML,
  MOVEMENT_STOP_XML,
  MOVEMENT_TURN_XML,
  MOVEMENT_WALK_BACKWARDS_XML,
  MOVEMENT_WALK_FORWARD_XML, MOVEMENT_WALK_TOWARDS_GRANDMA_XML, MOVEMENT_WALK_TOWARDS_OPTION_XML
} from "@/toolbox/categories/Movement";
import {
  SENSE_AM_I_HOLDING_XML, SENSE_AM_I_NEAR, SENSE_ARE_MORE_OBJECTS,
  SENSE_IS_OBJECT_XML,
  SENSE_IS_OBSTALCE_XML,
  SENSING_CATEGORY_XML
} from "@/toolbox/categories/Sensing";
import {
  STORY_IMAGINE_XML,
  STORY_MEMORY_XML,
  STORY_TELL_LIE_XML,
  STORYTELLING_CATEGORY_XML
} from "@/toolbox/categories/Storytelling";
import {TEXT_BLOCK_XML, TEXT_CATEGORY_XML, TEXT_JOIN_XML} from "@/toolbox/categories/Text";
import {
  SPEECH_ASK_INPUT_XML, SPEECH_ASK_NUMBER_XML,
  SPEECH_CATEGORY_XML,
  SPEECH_SAY_XML,
  SPEECH_WHISPER_XML,
  SPEECH_WRITE_AND_SAY_XML
} from "@/toolbox/categories/Speech";
import $ from 'jquery'

export default {
  name: 'BlocklyToolbox',
  data: function () {
    return {
      hiddenMessage: null,
      categoryEndXml: "</category>",
      toolboxXml: `<xml id="toolbox">`,
      toolboxEndXml: `</xml>`,
      categories: {
        logic: {
          visible: false,
          xml: LOGIC_XML,
          blocks: {
            ifControl: {
              visible: true,
              xml: IF_XML
            },
            compare: {
              visible: true,
              xml: COMPARE_XML
            },
            operation: {
              visible: true,
              xml: OPERATION_XML
            },
            negate: {
              visible: true,
              xml: NEGATE_XML
            },
            boolean: {
              visible: true,
              xml: BOOLEAN_XML
            },
            ternary: {
              visible: true,
              xml: TERNARY_XML
            }
          }
        },
        loops: {
          visible: false,
          xml: LOOPS_XML,
          blocks: {
            repeat: {
              visible: true,
              xml: REPEAT_XML
            },
            whileUntil: {
              visible: true,
              xml: WHILE_UNTIL_XML
            },
            //TODO: for is broken
            // for: {
            //     visible: true,
            //     xml: FOR_XML
            // },
            forEach: {
              visible: true,
              xml: FOR_EACH_XML
            },
            flowStatements: {
              visible: true,
              xml: FLOW_STATEMENTS_XML
            }

          }
        },
        math: {
          visible: false,
          xml: MATH_CATEGORY_XML,
          blocks: {
            number: {
              visible: true,
              xml: NUMBER_XML
            },
            arithmetic: {
              visible: true,
              xml: ARITHMETIC_XML
            },
            single: {
              visible: true,
              xml: SINGLE_XML
            },
            random: {
              visible: true,
              xml: RANDOM_NUMBER_XML
            }
          }
        },
        text: {
          visible: false,
          xml: TEXT_CATEGORY_XML,
          blocks: {
            text: {
              visible: true,
              xml: TEXT_BLOCK_XML
            },
            textJoin: {
              visible: true,
              xml: TEXT_JOIN_XML
            }
          }
        },
        arms: {
          visible: false,
          xml: ARMS_CATEGORY_XML,
          blocks: {
            raise: {
              visible: true,
              xml: RAISE_ARMS_XML
            },
            lower: {
              visible: true,
              xml: LOWER_ARMS_XML
            },
            bendElbow: {
              visible: true,
              xml: BEND_ELBOW_XML
            },
            straightenElbow: {
              visible: true,
              xml: STRAIGHTEN_ELBOW_XML
            },
            pickup: {
              visible: true,
              xml: PICKUP_XML
            },
            pickupSpecific: {
              visible: true,
              xml: PICKUP_SPECIFIC_XML
            },
            placeObject: {
              visible: true,
              xml: PLACE_OBJECT_XML
            },
            drop: {
              visible: true,
              xml: DROP_XML
            },
            putDown: {
              visible: true,
              xml: PUT_DOWN_XML
            },
            clap: {
              visible: true,
              xml: CLAP_XML
            },
            click: {
              visible: true,
              xml: CLICK_XML
            },
            point: {
              visible: true,
              xml: POINT_XML
            },
            pointAt: {
              visible: true,
              xml: POINT_AT_XML
            },
            specific: {
              visible: true,
              xml: SPECIFIC_ARMS_XML
            },
            removeLid: {
              visible: true,
              xml: REMOVE_PEN_LID_XML
            },
            putOnLid: {
              visible: true,
              xml: PUT_ON_PEN_LID_XML
            }
          }
        },
        legs: {
          visible: false,
          xml: LEGS_CATEGORY_XML,
          blocks: {

            step: {
              visible: true,
              xml: STEP_XML
            },
            kick: {
              visible: true,
              xml: KICK_XML
            },
            sideKick: {
              visible: true,
              xml: SIDE_KICK_XML
            },
            specificLegs: {
              visible: true,
              xml: SPECIFICS_LEGS_XML
            },
            raiseLegs: {
              visible: true,
              xml: RAISE_LEGS_XML
            },
            lowerLegs: {
              visible: true,
              xml: LOWER_LEGS_XML
            },
            bendKnees: {
              visible: true,
              xml: BEND_KNEES_XML
            },
            straightenKnees: {
              visible: true,
              xml: STRAIGHTEN_KNEES_XML
            }
          },
        },
        movement: {
          visible: false,
          xml: MOVEMENT_CATEGORY_XML,
          blocks: {
            walkForwards: {
              visible: true,
              xml: MOVEMENT_WALK_FORWARD_XML
            },
            walkBackwards: {
              visible: true,
              xml: MOVEMENT_WALK_BACKWARDS_XML
            },
            stepSideways: {
              visible: true,
              xml: MOVEMENT_STEP_SIDEWAYS_XML
            },
            turn: {
              visible: true,
              xml: MOVEMENT_TURN_XML
            },
            retraceSteps:{
              visible: true,
              xml: MOVEMENT_RETRACE_STEPS_XML
            },
            stop: {
              visible: true,
              xml: MOVEMENT_STOP_XML
            },
            crouch: {
              visible: true,
              xml: MOVEMENT_CROUCH_XML
            },
            jump: {
              visible: true,
              xml: MOVEMENT_JUMP_XML
            },
            sit: {
              visible: true,
              xml: MOVEMENT_SIT_XML
            },
            stand: {
              visible: true,
              xml: MOVEMENT_STAND_XML
            },
            hop: {
              visible: true,
              xml: MOVEMENT_HOP_XML
            },
            crawlForwards: {
              visible: true,
              xml: MOVEMENT_CRAWL_FORWARDS_XML
            },
            crawlBackwards: {
              visible: true,
              xml: MOVEMENT_CRAWL_BACKWARDS_XML
            },
            walkTowardsGrandma: {
              visible: true,
              xml: MOVEMENT_WALK_TOWARDS_GRANDMA_XML
            },
            walkTowardsOption: {
              visible: true,
              xml: MOVEMENT_WALK_TOWARDS_OPTION_XML
            }

          }

        },
        speech: {
          visible: false,
          xml: SPEECH_CATEGORY_XML,
          blocks: {
            say: {
              visible: true,
              xml: SPEECH_SAY_XML
            },
            whisper: {
              visible: true,
              xml: SPEECH_WHISPER_XML
            },
            writeAndSay: {
              visible: true,
              xml: SPEECH_WRITE_AND_SAY_XML
            },
            askInput: {
              visible: true,
              xml: SPEECH_ASK_INPUT_XML
            },
            askNumber: {
              visible: true,
              xml: SPEECH_ASK_NUMBER_XML
            }
          }
        },events: {
          visible: false,
          xml: EVENTS_CATEGORY_XML,
          blocks: {
            whenEvent: {
              visible: true,
              xml: EVENTS_WHEN_XML
            },
            waitSeconds: {
              visible: true,
              xml: EVENTS_WAIT_SECONDS_XML
            },
            waitWhileUntil: {
              visible: true,
              xml: EVENTS_WAIT_WHILE_UNTIL_XML
            },
          }
        },
        sensing: {
          visible: false,
          xml: SENSING_CATEGORY_XML,
          blocks: {

            isObstacle: {
              visible: true,
              xml: SENSE_IS_OBSTALCE_XML
            },
            amIHolding: {
              visible: true,
              xml: SENSE_AM_I_HOLDING_XML
            },
            isObject: {
              visible: true,
              xml: SENSE_IS_OBJECT_XML
            },
            amINear:{
              visible: true,
              xml: SENSE_AM_I_NEAR
            },
            areMoreObjects: {
              visible: true,
              xml: SENSE_ARE_MORE_OBJECTS
            },
            // isTexture: {
            //     visible: true,
            //     xml: SENSE_IS_OBJECT_TEXTURE_XML
            // },
            // isContents: {
            //     visible: true,
            //     xml: SENSE_IS_OBJECT_CONTENTS_XML
            // },

            // isMoving: {
            //     visible: true,
            //     xml: SENSE_IS_MOVING_XML
            // },
            // isLooking: {
            //     visible: true,
            //     xml: SENSE_IS_LOOKING_XML
            // },
            // isMakingNoise: {
            //     visible: true,
            //     xml: SENSE_IS_MAKING_NOISE_XML
            // },
            // isInPosition: {
            //     visible: true,
            //     xml: SENSE_IS_IN_POSITION_XML
            // },
            // isTouching: {
            //     visible: true,
            //     xml: SENSE_IS_TOUCHING_XML
            // },
            // didXSeeYMove: {
            //     visible: true,
            //     xml: SENSE_DID_X_SEE_Y_MOVE_XML
            // }
          }
        },
        storytelling: {
          visible: false,
          xml: STORYTELLING_CATEGORY_XML,
          blocks: {
            imagine: {
              visible: true,
              xml: STORY_IMAGINE_XML
            },
            memory: {
              visible: true,
              xml: STORY_MEMORY_XML
            },
            lie: {
              visible: true,
              xml: STORY_TELL_LIE_XML
            }

          }

        },
        variables: {
          visible: false,
          xml: `<category name="Variables" categorystyle="variable_category" custom="VARIABLE" iconclass="blocklyTreeIconCustom variables" css-container="blocklyToolboxCategory variables">`
        },
        functions: {
          visible: false,
          xml: `<category name="My blocks" categorystyle="procedure_category" custom="PROCEDURE" iconclass="blocklyTreeIconCustom functions" css-container="blocklyToolboxCategory functions">`
        },
        remote: {
          visible: false,
          xml: `<category name="Team Blocks" categorystyle="remote_category" iconclass="blocklyTreeIconCustom remote" css-container="blocklyToolboxCategory remote">
                            <label text="Team Blocks" web-class="blocklyFlyoutHeading"></label>`,
          blocks: {}
        },
        messages: {
          visible: false,
          xml: ""
        }
      }
    }
  },
  methods: {
    onHiddenMessage: function (messageData) {
      if (messageData.body) {
        this.hiddenMessage = {
          title: "Some other blocks",
          body: messageData.body,
          buttonPrompt: "OK"
        };
        this.glitchHiddenMessage();
      } else {
        this.hiddenMessage = null;
      }

    },
    glitchHiddenMessage: function () {
      const titles = ["S*m_ ot88r 9l0ck5", "S*&3 oth6r bl0ck%", "**Incoming msg**", "Some other blocks", "88CLICK_HERE88"]
      let self = this;
      //store a reference to the category label. It's likely that the label itself won't be rendered yet
      //so we'll pick it up later...

      let label;

      function setNextTitle() {
        let nextTitle = titles[Math.floor(Math.random() * titles.length)];

        //we could due a full update through vue like this, but it's quite expensive and unnecessary:
        //self.hiddenMessage.title = nextTitle

        //so instead, we'll hack the DOM!
        if (!label) {
          label = $(`.blocklyTreeLabel:contains("${self.hiddenMessage.title}")`);
        }
        label.text(nextTitle)
        //apply any other glitch effects here
        //...

        //Carry on until the user has clicked on the category
        let isCategoryExpanded = label.parent().parent().attr("aria-selected") === "true"
        if (!isCategoryExpanded) {
          callAtNextInterval()
        }
      }

      function callAtNextInterval() {
        let randomTimeMs = Math.floor(Math.random() * 700 + 50);
        setTimeout(setNextTitle, randomTimeMs);
      }

      callAtNextInterval();


    },
    onRemoteBlocksUpdated: function (blocks) {
      let newBlockNames = Object.keys(blocks);
      this.categories.remote.blocks = {};
      newBlockNames.forEach(block => this.addRemoteBlock(block));
      this.categories.remote.visible = newBlockNames.length > 0;
      this.emitUpdated();
    },
    addRemoteBlock: function (block) {
      //TODO: refactor the event and data sent with it
      let type = `remote_executecommand_${block}`
      this.categories.remote.blocks[block] = {
        xml: `<block type="${type}"></block>`,
        visible: true
      }
    },
    // removeRemoteBlock: function(block){
    //     this.categories.remote.blocks[block.name] = null;
    // },
    generateXml: function () {

      let categoriesXml = Object.values(this.categories).filter(category => category.visible === true)
          .map(category => {
            let categoryXml = `${category.xml}\n`;
            if (category.blocks) {
              categoryXml += Object.values(category.blocks).filter(block => block.visible === true)
                  .map(block => block.xml)
                  .join("\n")
            }
            categoryXml += `</category>`;

            return categoryXml;
          })
          .join("\n");
      return categoriesXml ?
          `<xml>
                    ${categoriesXml}
                </xml>`
          : ''

    },
    emitUpdated: function () {
      let xml = this.generateXml();
      if (xml) {
        this.$emit('updated', xml);
      }
    }
  },
  emits: ["updated"],
  computed: {
    defaultCategoriesToShow: function () {
      return ["loops", "logic", "math", "arms", "legs", "movement", "sensing"]
    },
    defaultBlocksToHide: function () {
      return ["logic.ternary", "logic.compare",
        "sensing.isShape", "sensing.isSize", "sensing.isTexture", "sensing.isContents",
        "sensing.isMakingNoise", "sensing.isInPosition",
        "sensing.isTouching", "sensing.didXSeeYMove"]
    }
  },
  props: {
    toolboxConfig: Object,
    workspace: Object,
    eventsForTask: Array
  },
  created: function () {


    //TODO: this is duplicated below
    this.$watch("categories", this.emitUpdated, {deep: true, immediate: true});
    this.$root.$on('remote-blocks-updated', this.onRemoteBlocksUpdated);
    this.$root.$on('hidden-message-received', this.onHiddenMessage);

  },
  beforeDestroy: function () {
    this.$root.$off('remote-blocks-updated', this.onRemoteBlocksUpdated);
    this.$root.$off('hidden-message-received', this.onHiddenMessage);
  },
  watch: {
    hiddenMessage: {
      handler: function () {
        const self = this;
        if (this.hiddenMessage) {
          this.categories.messages.xml = `
                    <category name="${this.hiddenMessage.title}">
                      <label text="${this.hiddenMessage.body}"></label>
                        <button text="${this.hiddenMessage.buttonPrompt}"
                         callbackKey="hiddenMessageButtonPressed"></button>
                    `
          this.workspace.registerButtonCallback("hiddenMessageButtonPressed", function () {
            self.$root.$emit("send-student-message", {message: 'hiddenMessageSeen'});
          })
          this.categories.messages.visible = true;
        } else {
          this.categories.messages.xml = ""
          this.categories.messages.visible = false;
        }

        this.generateXml()
      },
      deep: true
    },

    toolboxConfig: function (toolboxConfig) {

      let instance = this;
      if (toolboxConfig.showCategories.length === 1 && toolboxConfig.showCategories[0] === "all") {
        Object.values(this.categories).forEach(category => category.visible = true);
      } else {
        if (toolboxConfig.showCategories.length === 1 && toolboxConfig.showCategories[0] === "default") {
          toolboxConfig.showCategories = this.defaultCategoriesToShow();
        }
        Object.values(this.categories).forEach(category => category.visible = false);
        toolboxConfig.showCategories.forEach(
            key => instance.categories[key].visible = true
        );
      }
      //Apply different rules for event handler blocks. Hidden if no events:
      this.categories.events.blocks.whenEvent.visible = this.eventsForTask && this.eventsForTask.length > 0;

      //Apply different rules for remote blocks. Hidden if no blocks deployed. Visible if there are
      this.categories.remote.visible = Object.values(this.categories.remote.blocks).length > 0;
      if (toolboxConfig.isPrimaryUser !== true) {
        this.categories.remote.visible = false
      }
      //Apply different rules for message category:
      this.categories.messages.visible = this.hiddenMessage ? true : false
      if (toolboxConfig.hideBlocks) {
        if (toolboxConfig.hideBlocks.length === 1 && toolboxConfig.hideBlocks[0] === "default") {
          toolboxConfig.hideBlocks = this.defaultBlocksToHide();
        }

        toolboxConfig.hideBlocks.forEach(path => {
              let pair = path.split('.')
              if (!this.categories[pair[0]] || !this.categories[pair[0]].blocks[pair[1]]) {
                Logger.warn("Invalid hideBlocks: " + path);
              }
              this.categories[pair[0]].blocks[pair[1]].visible = false;
            }
        )
      }
      this.emitUpdated();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
