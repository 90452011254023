<template>
  <div>
    <div class="login" v-if="hasActiveTask===false">
      <header class="user-name">
        <div class="box">
          <img class="classbot-logo" src="@/assets/img/logos/lex-logo-full.png" alt=""/>
        </div>

        <div class="box">
          <img class="goat-logo" src="@/assets/img/logos/GOAT-logo.png" alt="The Government Office of Aspirational Technology"/>
        </div>
      </header>
      <h2>Welcome to LexEdit v 450.1.2</h2>
      <span v-if="loginStage === 0 && studentName !== null">
                <span class="loading-bar stage-0"></span>
                  <p>There was a previous login from {{ studentName }}</p>
                  <p>Is that you?</p>
                  <button v-on:click="completeLogin">Yes</button>
                  <button v-on:click="forgetPreviousLogin">No</button>
                </span>
      <span v-if="loginStage === 0 && studentName === null">
                    <span class="loading-bar stage-1"></span>
                  <p><em>Warning: This interface is for authorised engineers only</em></p>
                  <p>This website will let you debug and add new instructions to your Edubot.
                  </p>
                  <p class="check">You must be an authorised engineer to continue.</p>
                  <button v-on:click="loginStage ++">Continue</button>
                </span>
      <span v-if="loginStage === 1">
                <span class="loading-bar stage-2"></span>
                  <p>Are you an authorised user?</p>
                  <button v-on:click="loginStage ++">Yes</button>
                  <button v-on:click="loginStage --">No</button>
                </span>
      <span v-if="loginStage === 2">
                    <span class="loading-bar stage-3"></span>
                  <p>Please enter your name or names:</p>
                  <input v-model.trim="studentName"/>
                  <button v-if="studentName !== null && studentName !==''" v-on:click="completeLogin">Continue</button>
                </span>
    </div>
    <!--              LOADING PAGE -->
    <div class="login"
         v-if="(hasActiveTask === false && loginStage === 3) || (hasActiveTask === true && isWaitingAuth === true)">
      <!-- If no active task, header will already be renedered-->
      <template v-if="hasActiveTask === true">
        <header class="user-name">
          <div class="box">
            <img class="classbot-logo" src="@/assets/img/logos/lex-logo-full.png" alt=""/>
          </div>
          <div class="box">
            <img class="goat-logo" src="@/assets/img/logos/GOAT-logo.png" alt="The Government Office of Aspirational Technology"/>
          </div>
        </header>
        <h2>Welcome to LexEdit v 450.2.4</h2>
      </template>
      <span class="loading-bar stage-5"></span>
      <div>
        <p class="loading-msg">Connecting to Edubot 450 LeX... Loading</p>
        <img class="loading-gif" src="@/assets/img/icons/blue-loading-gif-transparent.gif" alt=""/>
      </div>

    </div>
    <header v-if="hasActiveTask === true && isWaitingAuth === false" class="user-name">
      <div class="box">
        <img class="classbot-logo" src="@/assets/img/logos/lex-logo-full.png" alt=""/>
      </div>
      <div class="box">
        <span class="welcome">Welcome, {{ studentName }}</span>
      </div>
      <div class="box">
        <img class="goat-logo" src="@/assets/img/logos/GOAT-logo.png" alt="The Government Office of Aspirational Technology"/>
      </div>
    </header>
    <local-cache :login-confirmed="loginStage === 3" :new-student-name="studentName"
                 :current-workspace="currentWorkspace" @student-name-loaded="studentNameLoaded"
                 :student-id="studentId"></local-cache>
  </div>
</template>

<script>
import LocalCache from "@/components/LocalCache.vue";
import {mapState} from "vuex";

export default {
  data: function () {
    return {
      loginStage: 0,
      studentName: null
    }
  },
  props: {
    //hasActiveTask: Boolean,
    inOverview: Boolean,
    currentWorkspace: String,
    studentId: String,
    isWaitingAuth: Boolean
  },
  computed: mapState(['hasActiveTask']),
  emit: ["login-submitted", "forget-previous-login"],
  components: {LocalCache},
  methods: {
    completeLogin: function () {
      this.loginStage = 3;
      this.$emit("login-submitted", this.studentName);
      this.$root.$emit("login-submitted", this.studentName);
    },
    forgetPreviousLogin: function () {
      this.loginStage = 0;
      this.studentName = null;
      this.$emit("forget-previous-login");
      this.$root.$emit("forget-previous-login");
    },
    studentNameLoaded: function (name) {
      this.studentName = name;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
