<template>
  <div class="blocklyArea section content" ref="blocklyArea">
    <!--    <span v-if="remainingBlocks && remainingBlocks !== Infinity">You have {{remainingBlocks}} blocks left</span>-->
    <!--    <div class="blocklyArea" ref="blocklyArea">-->
    <div class="blocklyDiv" style="position: absolute" ref="blocklyDiv">
    </div>
    <!--    </div>-->

    <blockly-toolbox :toolbox-config="toolboxConfig" @updated="updateToolbox" :workspace="workspace" :events-for-task="eventsForTask"></blockly-toolbox>
    <remote-block-runner :workspace="workspace" :is-primary="isPrimaryUser" :remote-blocks-for-task="remoteBlocks" :remote-block-message="remoteBlockMessage"></remote-block-runner>
    <call-remote-block v-if="isPrimaryUser === true" :workspace="workspace" :remote-blocks="remoteBlocks"></call-remote-block>
    <lex-event-handler :workspace="workspace" :eventTriggerMessage="remoteBlockMessage" :readOnly="readOnly"></lex-event-handler>
    <xml ref="initialToolbox" style="display:none">
      <category name="Logic" categorystyle="logic_category" iconclass="blocklyTreeIconCustom logic">
        <label text="Logic" web-class="blocklyFlyoutHeading"></label>
        <block type="controls_if"></block>
        <block type="logic_compare"></block>
        <block type="logic_operation"></block>
        <block type="logic_negate"></block>
      </category>
    </xml>
  </div>
</template>

<script>


import {lexBlocklyTheme} from "@/blockly/Theme";
import RemoteBlockRunner from "@/components/RemoteBlockRunner.vue";
import CallRemoteBlock from "@/components/CallRemoteBlock.vue";
import LexEventHandler from "@/components/LexEventHandler.vue";
import _ from 'underscore'
import $ from 'jquery'
import BlocklyToolbox from "@/components/BlocklyToolbox.vue";

Blockly.ContextMenuRegistry.registry.unregister('collapseWorkspace')
Blockly.ContextMenuRegistry.registry.unregister('expandWorkspace')
Blockly.ContextMenuRegistry.registry.unregister('workspaceDelete')
Blockly.ContextMenuRegistry.registry.unregister('cleanWorkspace')
Blockly.ContextMenuRegistry.registry.unregister('blockComment')
Blockly.ContextMenuRegistry.registry.unregister('blockDisable')


export default {
  components: {LexEventHandler, CallRemoteBlock, RemoteBlockRunner, BlocklyToolbox},
  props: {
    sendEventJsons: Boolean,
    currentTaskMessage: Object,
    remoteWorkspaceMessage: Object,
    base64ToLoad: String,
    remoteBlockMessage: Object,
    blocklyOptions: Object,
    sendBase64Updates: Boolean,
    isPrimaryUser: Boolean,
    readOnly: Boolean
  },
  defaultInitialBlocks: `<xml><block type="start" deletable="false" movable="false"></block></xml>`,
  data() {
    return {
      remainingBlocks: null,
      workspace: null,
      codeToExecute: "",
      listeningForChange: false,
      toolboxConfig: null,
      remoteBlocks: null,
      disableOrphans: false,
      loadedTask: false

    }
  },
  computed: {
    eventsForTask () {
      return this.$store.state.eventsForTask;
    },
    activeTask () {
      return this.$store.state.activeTask
    }
  },
  defaultOptions: {
    comments: false,
    collapse: false,
    disable: false,
    grid:
        {
          spacing: 25,
          length: 3,
          colour: '#ccc',
          snap: true
        },
    horizontalLayout: false,
    maxBlocks: Infinity,
    maxInstances: {'test_basic_limit_instances': 3},
    maxTrashcanContents: 0,
    media: '/lib/pxt-blockly/media/',
    oneBasedIndex: true,
    readOnly: false,

    move: {
      scrollbars: true,
      drag: true,
      wheel: false,
    },
    toolboxPosition: 'start',
    toolboxOptions:
        {
          color: true,
          inverted: true
        },
    zoom:
        {
          controls: true,
          wheel: true,
          startScale: 1.0,
          maxScale: 4,
          minScale: 0.25,
          scaleSpeed: 1.1
        },
    renderer: 'pxt'
  },
  created() {
    this.$root.$on('load-saved-blocks', this.onLoadSavedBlocks);
    this.$root.$on('execution-complete', this.clearHighlighted);
    this.$root.$on('block-highlighted', this.highlightBlock);
  },
  mounted() {

    let instance = this;
    Blockly.JavaScript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
    //javascript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
    Blockly.JavaScript.addReservedWords('highlightBlock');
    //javascript.addReservedWords('highlightBlock');

    let options = _.defaults(_.clone(this.blocklyOptions || {}), this.$options.defaultOptions)
    if (!options.toolbox) {
      options.toolbox = this.$refs['initialToolbox'];
    }
    if(lexBlocklyTheme){
      options.theme = lexBlocklyTheme;
    }
    this.workspace = Blockly.inject(this.$refs['blocklyDiv'], options);

    window.addEventListener('resize', this.onResize, false);

    this.onResize();
    Blockly.svgResize(this.workspace);

    function respondToVisibility(element, callback) {
      let options = {
        root: document.documentElement,
      };

      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          callback(entry.intersectionRatio > 0);
        });
      }, options);
      observer.observe(element);
    }

    respondToVisibility(this.$refs['blocklyArea'], (isVisible) => {
      if (isVisible) {
        instance.onResize();
      }
    })


    //see: https://stackoverflow.com/questions/38616167/communication-between-sibling-components-in-vue-js-2-0

    //TODO: loading saved blocks will cause the change listener to tricker for each saved block...

    this.$nextTick(function () {
      this.workspace.addChangeListener(this.onChange);
      if (this.disableOrphans) {
        this.workspace.addChangeListener(Blockly.Events.disableOrphans);
      }
      this.listeningForChange = true
    });

    if (this.currentTaskMessage) {
      this.processCurrentTaskMessage(this.currentTaskMessage);
    }
  },
  unmounted() {
    this.workspace = null;
    this.$root.$off('load-saved-blocks', this.onLoadSavedBlocks);
    this.$root.$off('execution-complete', this.clearHighlighted);
    this.$root.$off('block-highlighted', this.highlightBlock);
    this.workspace.removeChangeListener(this.onChange);
    this.workspace.removeChangeListener(Blockly.Events.disableOrphans);


  },
  emits: ["event-json-updated", "base64-updated", "code-updated"],
  watch: {
    currentTaskMessage: {
      handler: 'processCurrentTaskMessage',
    },
    remoteWorkspaceMessage: {
      handler: 'processRemoteWorkspaceMessage',
      immediate: true
    },
    base64ToLoad: {
      handler: 'loadBase64Workspace',
      immediate: true
    },
  },
  methods: {
    onResize: function () {
      let blocklyArea = this.$refs['blocklyArea'];
      let blocklyDiv = this.$refs['blocklyDiv'];
      if(blocklyArea) {


        // Compute the absolute coordinates and dimensions of blocklyArea.
        //let element = blocklyArea;
        let x = 0;
        let y = 0;
        // do {
        //     x += element.offsetLeft;
        //     y += element.offsetTop;
        //     element = element.offsetParent;
        // } while (element);
        // Position blocklyDiv over blocklyArea.
        blocklyDiv.style.left = x + 'px';
        blocklyDiv.style.top = y + 'px';
        blocklyDiv.style.width = blocklyArea.offsetWidth + 'px';
        blocklyDiv.style.height = blocklyArea.offsetHeight + 'px';
        Blockly.svgResize(this.workspace);
      }
    },
    processCurrentTaskMessage: function (message) {
      let self = this;
      if (message) {
        let msgType = message.message;
        if (msgType === 'startSession') {
          //note we are testing on task id not session id so you could conceivably have problems but it's pretty unlikely
          if(this.activeTask.taskId === message.id && this.loadedTask == true){
            console.log("Ignoring start session message - the same session is already in progress in this workspace")
          }
          else {


            //TODO: these blockly options are not currently implemented
            // let blocklyOptions = {
            //     maxBlocks: Number.isInteger(message.maxBlocks) ? message.maxBlocks : Infinity,
            //
            // };
            this.disableOrphans = message.disableOrphans === true
            let blocksToLoad;
            if (this.isPrimaryUser && message.primaryInitialBlocks) {
              blocksToLoad = atob(message.primaryInitialBlocks)
            } else if (message.initialBlocks) {
              blocksToLoad = atob(message.initialBlocks);
            } else {
              blocksToLoad = this.$options.defaultInitialBlocks;
            }
            if (message.events) {
              this.$store.commit('replaceEvents', message.events)
            } else {
              this.$store.commit('clearEvents')
            }

            //this.eventsForTask = message.events;
            this.remoteBlocks = message.remoteBlocks;

            //let blockly finish mounting before we try to load blocks:
            this.$nextTick(function () {
              this.loadBlocks(blocksToLoad);
              if (this.isPrimaryUser && message.customPrimaryToolbox) {
                this.toolboxConfig = message.customPrimaryToolbox
              } else if (message.customToolbox) {
                this.toolboxConfig = message.customToolbox
              } else {
                this.toolboxConfig =
                    {
                      showCategories: ["default"],
                      hideBlocks: ["default"]
                    }
              }

              this.toolboxConfig.isPrimaryUser = this.isPrimaryUser || false;
              this.loadedTask = true

            });
          }
        } else if (msgType === 'stopSession'){
          this.remoteBlocks = null;
          this.$store.commit('clearEvents')


        } else if (msgType === 'loadWorkspace') {
          $.ajax({
            url: `/api/student/workspace/${message.workspaceId}`,
            type: 'GET'
          })
              .done((data) => {
                if (data.workspaceBase64) {
                  self.loadBase64Workspace(data.workspaceBase64);
                }

              })
              .fail(jqXHR => Logger.error(JSON.stringify(jqXHR)))
        }
        else if( msgType === 'updateToolboxContents') {
          if(message.showCategories) {
            this.toolboxConfig.showCategories = message.showCategories;
          }
          if(message.hideBlocks) {
            this.toolboxConfig.hideBlocks = message.hideBlocks;
          }
        }
      }
    },

    updateToolbox: function (newToolbox) {

      if (newToolbox && this.workspace && this.toolboxConfig) {
        this.workspace.updateToolbox(newToolbox);

        this.workspace.toolbox_.flyout_.hide();

      } else {
        //note - we can't destroy a toolbox. So if this is needed, we need to work around
      }


    },
    generateCodeToExecute: function () {
      let code = Blockly.JavaScript.workspaceToCode(this.workspace);
      if(!(this.eventsForTask && this.eventsForTask.length > 0)){
        code += `sendCommand('End of program', 500, 'DIAGNOSTICS');\n`
      }
      code += `highlightBlock(null);`;
      this.codeToExecute = code;
    },
    emitBase64Workspace: function () {
      let workspaceAsXmlString = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.workspace));
      let base64 = btoa(workspaceAsXmlString);
      this.$emit('base64-updated', base64);

    },
    emitEventJsons: function (event) {
      if (event instanceof Blockly.Events.Ui) {
        return; //ignore ui events
      }
      this.$emit('event-json-updated', event.toJson())
    },
    clearHighlighted: function () {
      this.workspace.highlightBlock(null)
    },
    highlightBlock: function (id) {
      this.workspace.highlightBlock(id);
      if (id) {
        //this.workspace.centerOnBlock(id);
      }


    },
    onLoadSavedBlocks: function (blocks) {


      //TODO: do we ever want to prompt the user to confirm?
      this.$nextTick(function () {

        this.loadBlocks(atob(blocks.workspace));
      });
    },
    onChange: function (event) {
      if(event instanceof Blockly.Events.Ui){
        //don't mirror UI events
        return;
      }
      if (event && this.sendEventJsons === true) {
        this.emitEventJsons(event);
      }
      this.remainingBlocks = this.workspace.remainingCapacity();
      this.generateCodeToExecute();
      this.$emit('code-updated', this.codeToExecute);
      if (this.sendBase64Updates) {
        this.emitBase64Workspace();
      }

    },
    processRemoteWorkspaceMessage: function (message) {
      if (message) {
        this.$nextTick(function () {
          let msgType = message.message;
          if (msgType === 'currentWorkspace') {
            let xmlText = "";
            if (message.base64) {
              xmlText = atob(message.base64);
            }
            this.loadBlocks(xmlText)
          } else if (msgType === 'workspaceEvent') {
            let json = JSON.parse(atob(message.content))
            let event = Blockly.Events.fromJson(json, this.workspace);
            event.run(true);
          } else if (msgType === 'highlightBlock') {
            this.highlightBlock(message.blockId);
          }
        });
      }
    },
    loadBase64Workspace: function (base64) {
      if (base64) {
        //defer to next tick to wait for workspace to be loaded
        this.$nextTick(function () {
          let xmlText = atob(base64);
          this.loadBlocks(xmlText);
        })

      }
    },
    loadBlocks: function (blocks) {
      if (blocks && this.workspace) {
        //temporarily suspend change listeners to save processing while adding blocks
        // if (this.listeningForChange) {
        //     this.workspace.removeChangeListener(this.onChange);
        // }
        //clear any existing blocks in the workspace
        this.workspace.clear();

        let xml = Blockly.Xml.textToDom(blocks);
        Blockly.Xml.domToWorkspace(xml, this.workspace);

        // if (this.listeningForChange) {
        //     this.workspace.addChangeListener(this.onChange);
        // }
        //now process all changes in one go
        //this.onChange();
      }
    }
  }


}
</script>

