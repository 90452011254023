<template>
  <div class="outer-wrapper" id="serverListener">
    <div class="container box">
      <div class="section header">
        <!-- <iframe id="simulatorfor" src="/simulator/index.html" :class="{ hidden: hideSimulator, shown: !hideSimulator}"></iframe>             -->
        <engineer-login :in-overview="inOverview" @login-submitted="loginSubmitted" @forget-previous-login="studentName = ''" :current-workspace="blocksBase64"
                        :student-id="studentId"></engineer-login>
  <!--      <engineer-login :has-active-task="hasActiveTask"-->
  <!--                      :in-overview="inOverview" @login-submitted="loginSubmitted" @forget-previous-login="studentName = ''" :current-workspace="blocksBase64"-->
  <!--                      :student-id="studentId"></engineer-login>-->
        <active-task-viewer :is-primary-user="false" :current-task-message="currentTaskMessage"></active-task-viewer>
        <!--                This button is for debug purposes-->
        <!--                <button v-on:click="sendNonsense">Drop off server</button>-->
        <div v-if="hasActiveTask=== true && inOverview !== true">
          <div v-if="connectedToServer === false" class="warning">Please check your internet connection</div>
          <lex-runner :auth-code="lexAuthCode" reset-button-name="Reset"
                      :current-task-message="currentTaskMessage" :code-to-execute="codeToExecute"
                      :remote-block-message="remoteBlockMessage"
                      @remote-execution-complete="sendStudentMessage"></lex-runner>

        </div>
        <workspace-saver auth-code="notImplemented" :task-id="activeTask.sessionId" :student-name="studentName" :student-id="studentId"
                         :current-workspace-base64="blocksBase64"></workspace-saver>
      </div>
      <blockly-component v-if="hasActiveTask === true && inOverview === false"
               :current-task-message="currentTaskMessage"
               :send-event-jsons="mirrorCodeToServer"
               :send-base64-updates="true"
               :remote-block-message="remoteBlockMessage"
               @code-updated="codeToExecute = $event" @event-json-updated="sendWorkspaceUpdateMessage"
               @base64-updated="blocksBase64 = $event"></blockly-component>

    </div>
  </div>
</template>
<script>




import {WebSocketClient} from "@/WsClient";

import {
  engineerMessages
} from "@/util/engineerEventMessages";
import _ from 'underscore';
import $ from 'jquery';
import EngineerLogin from "@/components/EngineerLogin.vue";
import ActiveTaskViewer from "@/components/ActiveTaskViewer.vue";
import LexRunner from "@/components/LexRunner.vue";
import WorkspaceSaver from "@/components/WorkspaceSaver.vue";
import {mapState} from "vuex";
import BlocklyComponent from "@/components/BlocklyComponent.vue";

export default {
  name: 'EngineerView',
  components: {
    WorkspaceSaver,
    LexRunner,
    ActiveTaskViewer,
    EngineerLogin,
    BlocklyComponent
  },
  computed: mapState(['hasActiveTask', 'activeTask']),
  data() {
   return {
     //the name of this student. Set by student
     studentName: "",
     studentId: null,
     //TODO: move me to store
     //hasActiveTask: false,
     //activeTask: {},
     inOverview: false,
     connectedToServer: false,
     lexAuthCode: null,
     mirrorCodeToServer: false,
     codeToExecute: null,
     blocksBase64: null,
     remoteBlockMessage: null,
     currentTaskMessage: null,
     studentWs: null,
     pendingMessageOnReconnect: null
   }
  },
  watch: {
    hasActiveTask: function(newValue){
      if(newValue === false){
        this.blocksBase64 = null;
        this.codeToExecute = null;
      }
    }
  },
  //mixins: [wsClient],
  mounted: function() {
    const app = this;
    this.$root.$on('block-highlighted', function(id) {
      if(app.mirrorCodeToServer===true){
        app.sendHighlightBlockMessage(id);
      }
    })

    this.$root.$on('overview-set', function(showOverview) {
      this.inOverview = showOverview;
    })

    this.$root.$on('send-student-message', this.sendStudentMessage)

    this.$root.$on('student-id-changed', function(oldAndNewIds){
      let restoreSessionMessage = engineerMessages.restorePreviousSession(oldAndNewIds.old, this.studentName, this.primaryStudentAuthCode);
      if(this.studentWs){
        this.studentWs.send(restoreSessionMessage)
      }
      else{
        this.pendingMessageOnReconnect = restoreSessionMessage;
      }

    });


  },
  methods: {
    loginSubmitted: function (name) {
      this.studentName = name;
      this.connectToServer();
    },
    connectToServer: function () {
      let protocol = location.protocol === "https:" ? "wss:" : "ws:";
      let url = `${protocol}//${location.hostname}:${window.location.port}/ws/student`
      this.studentWs = WebSocketClient(url, "message");

      this.studentWs.init(
          this.processStudentWsMessage,
          () => {
            this.connectedToServer = true;
            this.sendUpdateDataMessage();
            if(this.pendingMessageOnReconnect){
              this.studentWs.send(this.pendingMessageOnReconnect)
              this.pendingMessageOnReconnect = null;
            }
          },
          (e) => Logger.error(e),
          () => {
            this.connectedToServer = false;
            this.lexAuthCode = null;
            //TODO: any other reset?
          }
      );
    },
    processStudentWsMessage: function (data) {

      console.log("received " + JSON.stringify(data))

      let msgType = data.message;
      if (msgType === 'startSession') {
        let self = this;
        $.ajax({
          url: `/api/tasks/${data.id}`,
          type: 'GET'
        })
            .done((task) => {
              self.currentTaskMessage = _.extend(task, data);

            })
            .fail(jqXHR => Logger.error(JSON.stringify(jqXHR)))
      }
      if (['stopSession', 'pauseSession', 'resumeSession', 'markSuccessful', 'loadWorkspace', 'updateToolboxContents'].includes(msgType)) {
        this.currentTaskMessage = data;
      }
      if (msgType === 'studentSessionId') {
        this.studentId = data.studentId
      }
      if (msgType === 'connectedToLex') {
        this.lexAuthCode = data.authKey;
      } else if (msgType === 'disconnectedFromLex') {
        this.lexAuthCode = null;
      } else if (msgType === 'sendWorkspace') {
        if (this.blocksBase64 !== null) {
          this.studentWs.send(engineerMessages.currentWorkspace(this.blocksBase64));
          if (data.mirrorWorkspace === true) {
            this.mirrorCodeToServer = true;
          }
        } else {
          Logger.warn("blocksBase64 has not been set. Not sending workspace")
        }
      } else if (msgType === 'stopSendWorkspace') {
        this.mirrorCodeToServer = false;
      } else if (msgType === 'executeRemoteBlock' || msgType === 'addRemoteBlock' || msgType === 'removeRemoteBlock' || msgType === "stopRemoteExecution" || msgType === "triggerLexEvent") {
        this.remoteBlockMessage = data;
        //TODO: this needs refactoring but for now a quick bugfix to highlight remote blocks when executing
        if(msgType === 'executeRemoteBlock'){
          this.mirrorCodeToServer = true
        }
      } else if (msgType === 'hiddenMessage') {
        this.$root.$emit("hidden-message-received", data);
      }
    },
    sendStudentMessage: function (message) {
      this.studentWs.send(message);
    },
    sendUpdateDataMessage: function () {
      this.studentWs.send(engineerMessages.updateStudentData(this.studentName));
    },
    sendWorkspaceUpdateMessage: function (event) {
      this.studentWs.send(engineerMessages.workspaceUpdate(event));
    },
    sendHighlightBlockMessage: function (blockId) {
      this.studentWs.send(engineerMessages.highlightBlock(blockId))
    },
    getBlocksBase64: function () {

      copyToClipboard(this.blocksBase64).then(function () {
        alert("copied to clipboard")
      }, function (e) {
        console.log(e)
      });
    }

  }
}





function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}
</script>
