export const ARMS_CATEGORY_XML=`
<category name="Arms" categorystyle="arms_category" iconclass="blocklyTreeIconCustom arms" css-container="blocklyToolboxCategory arms">
        <label text="Arms" web-class="blocklyFlyoutHeading"></label>`
export const RAISE_ARMS_XML=`<block type="arms_raise">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`
export const LOWER_ARMS_XML=`
        <block type="arms_lower">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`
export const BEND_ELBOW_XML = `
        <block type="elbow_bend">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`
export const STRAIGHTEN_ELBOW_XML=`
        <block type="elbow_straighten">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`
export const PICKUP_XML=`<block type="arms_pickup"></block>`
export const PICKUP_SPECIFIC_XML=`<block type="arms_pickup_specific"></block>`
export const DROP_XML= `<block type="arms_drop"></block>`
export const PUT_DOWN_XML=`<block type="arms_putdown"></block>`
export const PLACE_OBJECT_XML=`<block type="arms_placeobject">
    <value name="WHERE">
                <shadow type="text">
                    <field name="TEXT">on my head</field>
                </shadow>
            </value>
</block>`
export const CLAP_XML=`<block type="hands_clap">
            <value name="TIMES">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`
export const CLICK_XML=`<block type="hands_click"></block>`
export const REMOVE_PEN_LID_XML=`<block type="arms_removelid"></block>`
export const PUT_ON_PEN_LID_XML=`<block type="arms_putonlid"></block>`
export const POINT_XML=`<block type="arms_point"></block>`
export const POINT_AT_XML=
    `<block type="arms_pointat">
            <value name="TARGET">
                <shadow type="text">
                    <field name="TARGET"></field>
                </shadow>
            </value>
        </block>`
export const SPECIFIC_ARMS_XML=`
        <block type="arms_specific">
            <value name="DESCRIPTION">
                <shadow type="text">
                    <field name="TEXT">straight out to the side</field>
                </shadow>
            </value>
        </block>`