<template>
<span></span>
</template>

<script>
import _ from 'underscore'
import {mapState} from "vuex";

export default {
  name: 'CallRemoteBlock',
  props: {
    workspace: Object,
    remoteBlocks: Array

  },
  data: function () {
    return {
      executeOnRemoteBlockIds: [],
      studentDropdownOptions: [],
      studentIdsToRemap: {}
    }
  },
  computed: mapState(['connectedStudents', "hasActiveTask"]),
  watch: {
    remoteBlocks: {
      handler: 'handleRemoteBlocksChanged',
      immediate: true
    },
    connectedStudents: {
      handler: 'updateRemoteBlockDropdowns',
      immediate: true
    },
    hasActiveTask: function(newValue) {
      if(newValue === false){
        self.executeOnRemoteBlockIds = [];
        self.studentDropdownOptions = null
      }

    }
  },
  remoteBlockPrefix: 'remote_executecommand_',
  created() {
    let self = this;
    this.$root.$on('remote-blocks-updated', this.blocksUpdated);
    this.$root.$on('student-session-restored', function (msg) {
      self.remapRemoteBlocksWithOldStudentIdToNewId(msg.previousId, msg.id)
    });
  },
  beforeDestroy() {
    let self = this;
    this.$root.$off('remote-blocks-updated', this.blocksUpdated);
    this.$root.$off('student-session-restored', function (msg) {
      self.remapRemoteBlocksWithOldStudentIdToNewId(msg.previousId, msg.id)
    });
    this.workspace.removeChangeListener(this.onChange);

  },
  mounted() {


    this.$nextTick(function () {
          this.workspace.addChangeListener(this.onChange);
        }
    );

  },
  methods: {
    handleRemoteBlocksChanged: function () {
      let instance = this;
      if(this.remoteBlocks){
        this.remoteBlocks.forEach(block => instance.setupRemoteBlock(block.name, block.returnType, true))
      }
    },
    setupRemoteBlock: function (name, returnType, forceReset) {
      if (Blockly.Blocks[`${this.$options.remoteBlockPrefix}${name}`] && forceReset !== true) {
        Logger.warn("already set up remote block: skipping...");
        return;
      }

      let instance = this;
      Blockly.Blocks[`${this.$options.remoteBlockPrefix}${name}`] = {
        init: function () {
          this.appendDummyInput()
              .appendField("Run ")
              .appendField(new Blockly.FieldDropdown(instance.getStudentDropdownOptionsSupplier(name)
                  , function (value) {
                    let blockId = instance.executeOnRemoteBlockIds.find(block => block.name === name);
                    if (blockId) {
                      return instance.studentDropDownValidateValueForBlock(name, blockId.blockId, value);
                    } else {
                      return value;
                    }

                  }), 'STUDENT_ID');
          this.appendDummyInput()
              .appendField("'s block: ")
              .appendField(name)

          this.setInputsInline(true);
          if (returnType) {
            this.setOutput(true);
          } else {
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
          }
          this.setStyle("remote_blocks");
          this.setTooltip("");
          this.setHelpUrl("");

        }
      };

      Blockly.JavaScript[`${this.$options.remoteBlockPrefix}${name}`] = function (block) {
        let student_id = block.getFieldValue('STUDENT_ID');
        if (student_id !== '') {
          let code = `
                    executeRemoteBlock('${name}', '${student_id}', null)\n`;
          if (returnType) {
            return [code, Blockly.JavaScript.ORDER_NONE];
          }
          return code;

        } else {
          let code = `(function(){throw JSON.stringify({ humanMessage: 'Please choose an engineer to run "${name}"'})})()\n`;
          if (returnType) {
            return [code, Blockly.JavaScript.ORDER_NONE];
          }
          return code

        }
      }
    },
    studentDropDownValidateValueForBlock: function (blockName, blockId, value) {
      let block = this.workspace.getBlockById(blockId);
      if (block) {


        let dropdown = block.getField('STUDENT_ID');
        let valueToSet = value;
        if (this.studentIdsToRemap[value]) {
          valueToSet = this.studentIdsToRemap[value]
          dropdown.setValue(valueToSet);
        }
        if (valueToSet === '') {
          //no warning but invalid
          block.setWarningText(null);
        }
        if (!this.studentDropdownOptions[blockName].find(item => item[1] === valueToSet)) {
          //warning and invalid
          block.setWarningText(`${dropdown.getText()} is not connected`)

        } else {
          //valid
          block.setWarningText(null);
        }
      }

      return value;
    },
    blocksUpdated: function (studentsByBlock) {
      this.studentDropdownOptions = _.clone(studentsByBlock);
      Object.entries(this.studentDropdownOptions).forEach(entry => {
        let blockName = entry[0];
        let students = entry[1].students;

        this.studentDropdownOptions[blockName] = students.map(item => {
          let nameToShow = item.name;
          if (nameToShow.length > 25) {
            nameToShow = nameToShow.substring(0, 25) + "...";
          }
          return [nameToShow, item.id]
        })

        this.studentDropdownOptions[blockName].splice(0, 0, ['Select an engineer', '']);
      })

      Object.entries(studentsByBlock).forEach(entry => {

        let blockName = entry[0];
        let returnType = entry[1].returnType
        this.setupRemoteBlock(blockName, returnType);
      });

      this.updateRemoteBlockDropdowns();

    }
    ,
    getStudentDropdownOptions: function (blockName) {
      return this.studentDropdownOptions[blockName] || [['Select an engineer', '']];
    },
    getStudentDropdownOptionsSupplier: function (blockName) {
      let instance = this;
      return function(){
        if(!instance.studentDropdownOptions){
          Logger.warn("No studentDropdownOptions found for component...");
          return  [['Select an engineer', '']]
        }
        return instance.studentDropdownOptions[blockName] || [['Select an engineer', '']]
      };
    }
    ,
    updateRemoteBlockDropdowns: function () {
      this.executeOnRemoteBlockIds.forEach(nameId => {

        let block = this.workspace.getBlockById(nameId.blockId);
        let dropdown = block.getField('STUDENT_ID');
        let value = dropdown.getValue();
        this.studentDropDownValidateValueForBlock(nameId.name, nameId.blockId, value);

      });
      this.studentIdsToRemap = {};
    },
    remapRemoteBlocksWithOldStudentIdToNewId(oldId, newId) {
      this.executeOnRemoteBlockIds.forEach(nameId => {

        let block = this.workspace.getBlockById(nameId.blockId);
        let dropdown = block.getField('STUDENT_ID');
        let value = dropdown.getValue();
        if (value === oldId) {
          if (this.studentDropdownOptions[nameId.name].find(item => item[1] === newId)) {
            dropdown.setValue(newId);
          } else {
            this.studentIdsToRemap[oldId] = newId;
          }
        }
      });
    },
    applyFunctionToAnyRemoteBlocksInXmlNode: function (node, idNameFunction) {
      let instance = this;
      if (node.attributes && node.attributes["type"]) {


        let type = node.attributes["type"].value;
        if (type.startsWith(this.$options.remoteBlockPrefix)) {
          let blockName = type.substring(this.$options.remoteBlockPrefix.length);
          idNameFunction(node.id, blockName);
          //this.executeOnRemoteBlockIds.push({blockId: node.id, name: blockName});
        }
      }
      node.childNodes.forEach(child => instance.applyFunctionToAnyRemoteBlocksInXmlNode(child, idNameFunction));
    },
    onChange: function (event) {
      let instance = this;
      if (event.type === Blockly.Events.BLOCK_CREATE) {

        // let type = event.xml.attributes["type"].value;
        this.applyFunctionToAnyRemoteBlocksInXmlNode(event.xml, function (id, name) {
          if(!instance.executeOnRemoteBlockIds.find(b=>b.name === name)){
            instance.executeOnRemoteBlockIds.push({blockId: id, name: name});
          }
        });

        // if (type.startsWith(this.$options.remoteBlockPrefix)) {
        //     let blockName = type.substring(this.$options.remoteBlockPrefix.length);
        //     this.executeOnRemoteBlockIds.push({blockId: event.blockId, name: blockName});
        // }

      } else if (event.type === Blockly.Events.BLOCK_DELETE) {

        this.applyFunctionToAnyRemoteBlocksInXmlNode(event.oldXml, function (id) {
          instance.executeOnRemoteBlockIds = instance.executeOnRemoteBlockIds.filter(block => block.blockId !== id)
        });
        // if (event.oldXml.attributes["type"].value.startsWith(this.$options.remoteBlockPrefix)) {
        //     this.executeOnRemoteBlockIds = this.executeOnRemoteBlockIds.filter(block => block.blockId !== event.blockId)
        //
        // }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
