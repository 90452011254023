import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'underscore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    facilitatorAuthKey: null,
    eventsForTask: [],
    hasActiveTask: false,
    activeTask: {},
    inTaskOverview: false,
    connectedStudents: [],
    chatClients: [],
    fxConnections: {
      chestDisplaysConnected: 0,
      podDisplaysConnected: 0
    }
  },
  getters: {
  },
  mutations: {
    replaceEvents(state, newEvents){
      state.eventsForTask = newEvents
    },
    clearEvents(state) {
      state.eventsForTask = []
    },
    setActiveTask(state, taskDetails) {
      if(taskDetails.taskId){
        state.activeTask = taskDetails
        state.hasActiveTask = true
      }
      else{
        state.activeTask = {}
        state.hasActiveTask = false
      }
    },
    clearActiveTask(state){
      state.activeTask = {}
      state.hasActiveTask = false
    },
    setInTaskOverview(state, value) {
      state.inTaskOverview = value;
    },
    setConnectedStudents(state, value) {
      state.connectedStudents = value;
    },
    addChatClient(state, value) {
      if(_.findIndex(state.chatClients, ref => ref.id === value.id) === -1){
        value.completed = value.completed || false
        state.chatClients.push(value)
      }
      else{
        Logger.warn(`won't add chat client. ID is already present: ${value.id}`)
      }

    },
    removeChatClient(state, id){
      state.chatClients = state.chatClients.filter(function (value) {
        return value.id !== id;
      });
    },
    updateChatClient(state, value){
      let indexToUpdate = _.findIndex(state.chatClients, ref => ref.id === value.id)
      state.chatClients.splice(indexToUpdate, 1, value)
    },
    setChatClients(state, value) {
      state.chatClients = value
    },
    setFxConnections(state, value){
      state.fxConnections.podDisplaysConnected = value.podDisplaysConnected || 0
      state.fxConnections.chestDisplaysConnected = value.chestDisplaysConnected || 0
    },
    setFacilitatorAuthKey(state, value){
      state.facilitatorAuthKey = value;
    }

  },
  actions: {
  },
  modules: {
  }
})
