export const MATH_CATEGORY_XML =
    `<category name="Math" categorystyle="math_category" iconclass="blocklyTreeIconCustom math"  css-container="blocklyToolboxCategory math">
        <label text="Maths" web-class="blocklyFlyoutHeading"></label>`
export const NUMBER_XML = `
    <block type="math_number" gap="32">
        <field name="NUM">123</field>
    </block>`
export const ARITHMETIC_XML = `
    <block type="math_arithmetic">
        <value name="A">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
        <value name="B">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const SINGLE_XML = `<block type="math_single">
                <value name="NUM">
                    <shadow type="math_number">
                        <field name="NUM">9</field>
                    </shadow>
                </value>
            </block>`
export const RANDOM_NUMBER_XML =
    `<block type="math_random_int">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>`
