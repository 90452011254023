export const LOOPS_XML =
    `<category name="Loops" categorystyle="loop_category" iconclass="blocklyTreeIconCustom loops" css-container="blocklyToolboxCategory loops">
        <label text="Loops" web-class="blocklyFlyoutHeading"></label>`;
export const REPEAT_XML =
    `<block type="controls_repeat_ext">
        <value name="TIMES">
            <shadow type="math_number">
                <field name="NUM">3</field>
            </shadow>
        </value>
     </block>`
export const WHILE_UNTIL_XML =
    `<block type="controls_whileUntil"></block>`
export const FOR_XML =
    `<block type="controls_for">
        <value name="VAR">
            <shadow type="variables_get_reporter">
                <field name="VAR">index</field>
            </shadow>
        </value>
        <value name="FROM">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
        <value name="TO">
            <shadow type="math_number">
                <field name="NUM">10</field>
            </shadow>
        </value>
        <value name="BY">
            <shadow type="math_number">
                <field name="NUM">1</field>
            </shadow>
        </value>
    </block>`
export const FOR_EACH_XML =
    `<block type="controls_forEach">
        <value name="VAR">
                <shadow type="variables_get_reporter">
                    <field name="VAR">item</field>
                </shadow>
            </value>
        </block>
`
export const FLOW_STATEMENTS_XML = `<block type="controls_flow_statements"></block>`
