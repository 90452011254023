const {getCommandToSend, setupBlockWithInputAndDropdown} = require("@/blockly/blocks/common");

//Block definitions:

//these are set up with new methods:

setupBlockWithInputAndDropdown("legs_specific", "Move {dropdown}:  {String}",3000,
    {
      argName: "DESCRIPTION",
      style: "legs_blocks",
      inputType: "String",
      dropdownOptions: ["my left leg", "my right leg", "both legs"],
      tooltip: "Describe how LeX should move her leg. Be precise or LeX might not understand."
    } )


//these are manually generated:

Blockly.Blocks['legs_raise'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Raise ");
    this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "leg")
        .appendField("leg by");
    this.appendValueInput("degrees")
        .setCheck("Number");
      this.appendDummyInput()
          .appendField("degrees");

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setStyle("legs_blocks");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

  Blockly.Blocks['legs_lower'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Lower ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "leg")
          .appendField("leg by");
      this.appendValueInput("degrees")
          .setCheck("Number");
        this.appendDummyInput()
            .appendField("degrees")

      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['knees_bend'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Bend ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "knee")
          .appendField("knee by");
      this.appendValueInput("degrees")
          .setCheck("Number");
        this.appendDummyInput()
            .appendField("degrees")

      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['knees_straighten'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Straighten ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "knee")
          .appendField("knee by");
      this.appendValueInput("degrees")
          .setCheck("Number");
        this.appendDummyInput()
            .appendField("degrees")
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['legs_kick'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kick with ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "leg")
          .appendField("leg");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['legs_step'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Step my ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "leg")
      this.appendDummyInput()
          .appendField("leg ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["forwards","forwards"], ["sideways","sideways"], ["back","back"]]), "DIRECTION")      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['legs_sidekick'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Kick to the side with ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["left","left"], ["right","right"]]), "leg")
          .appendField("leg");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("legs_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


//Generators:
  
Blockly.JavaScript['legs_raise'] = function(block) {
    var value_degrees = Blockly.JavaScript.valueToCode(block, 'degrees', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  
    var dropdown_leg = block.getFieldValue('leg');
    
    return getCommandToSend('"Raise your " +"' + dropdown_leg + '" + " leg by " + ' + value_degrees + ' + " degrees"', 4000);

  };

  Blockly.JavaScript['legs_lower'] = function(block) {
    var value_degrees = Blockly.JavaScript.valueToCode(block, 'degrees', Blockly.JavaScript.ORDER_ATOMIC) || 0;
  
    var dropdown_leg = block.getFieldValue('leg');
    
    return getCommandToSend('"Lower your " +"' + dropdown_leg + '" + " leg by " + ' + value_degrees + ' + " degrees"', 4000);

  };
  
  Blockly.JavaScript['knees_bend'] = function(block) {
    var value_degrees = Blockly.JavaScript.valueToCode(block, 'degrees', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    var dropdown_knee = block.getFieldValue('knee');
    return getCommandToSend('"Bend your " +"' + dropdown_knee + '" + " knee by " + ' + value_degrees + ' + " degrees"', 4000);
    

  };

  Blockly.JavaScript['knees_straighten'] = function(block) {
    var value_degrees = Blockly.JavaScript.valueToCode(block, 'degrees', Blockly.JavaScript.ORDER_ATOMIC) || 0;
    var dropdown_knee = block.getFieldValue('knee');
    return getCommandToSend('"Straighten your " +"' + dropdown_knee + '" + " knee by " + ' + value_degrees + ' + " degrees"', 4000);

  };

  Blockly.JavaScript['legs_kick'] = function(block) {    
  
    var dropdown_leg = block.getFieldValue('leg');    
    return getCommandToSend('"Kick your " +"' + dropdown_leg + '" + " leg "', 1500);

  };

  Blockly.JavaScript['legs_sidekick'] = function(block) {    
  
    var dropdown_leg = block.getFieldValue('leg');    
    return getCommandToSend('"Kick your " +"' + dropdown_leg + '" + " leg to the side"', 1500);

  };

  Blockly.JavaScript['legs_step'] = function(block) {    
  
    var dropdown_leg = block.getFieldValue('leg');    
    var direction = block.getFieldValue('DIRECTION');    
    return getCommandToSend(`'Step your ${dropdown_leg} leg ${direction}'`, 1500);

  };
