
export const getCommandToSend = function (textCommand, timeout, options) {
    if(!options){
        options = {}
    }
    //let commandName = options.commandName;
    //let commandArgs = options.commandArgs;
    //let category = options.category;
    //timeout is a default time this action will take. It can be overridden by LeX
    if (!timeout) {
        timeout = 6000;
    }
    //borken: see trello
    //return 'sendCommand('+ textCommand +', ' + timeout +', "'+ commandName +'",'+ commandArgs +');\n';
    return 'sendCommand(' + textCommand + ', ' + timeout + ');\n';

}

export const getCommandsToSend = function (textCommandArray, timeouts) {

    return textCommandArray.map((currentValue, index) => {

            let timeout = Array.isArray(timeouts) ? (timeouts[index] || 4000) : timeouts
            //warning this is broken: see trello
            // return `sendCommand(${currentValue}, ${timeout}, "${commandName}", ${commandArgsArray[index]});`
            return `sendCommand(${currentValue}, ${timeout});`
        }
    ).join("\n");
}


export const getQueryToSend = function (text, type, options) {
    if(!options){
        options = {}
    }
    if (!type) {
        type = 'boolean';
    }
    let prefix = options.prefix ? options.prefix : 'Question';

    let query = `sendQuery('${prefix}: '+${text}, '${type}')`;
    return query;
}

// function toTitleCase(str) {
//     return str.replace(
//         /\w\S*/g,
//         function (txt) {
//             return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//         }
//     );
// }

//TODO: Needs tests
export const degreesToHumanFriendlyTurns = function (degreesInput, isClockwise) {
    //TODO: Wouldn't it be better to not require the use of eval
    var degrees = degreesInput;
    if (typeof degreesInput === "string") {
        degrees = eval(degreesInput);
    }
    if (degrees === 0) {
        return "0 degrees"
    }
    //TODO: confused by negative numbers
    let fullTurns = Math.floor(degrees / 360);
    let remainderTurns = degrees % 360;
    let clockSegments = remainderTurns / 30;
    let roundedClockSegments = Math.round(clockSegments)
    let correction = clockSegments - roundedClockSegments;
    var modifier = ""
    if (Math.abs(correction) > 0.2) {
        modifier = (correction > 0) ? "just past" : "just before";
    }

    if (!isClockwise) {
        roundedClockSegments = 12 - roundedClockSegments;

        if (modifier.length < 0 && roundedClockSegments == 12) {
            roundedClockSegments = 0;
        }
    } else {
        if (modifier.length > 0 && roundedClockSegments == 0) {
            roundedClockSegments = 12;
        }
    }

    let phrase = "";
    if (fullTurns > 0) {
        phrase += ` turn all the way round ${fullTurns} times`;
    }
    if (clockSegments > 0) {
        if (phrase.length > 0) {
            phrase += ". Then continue ";
        }
        phrase += ` to ${modifier} ${roundedClockSegments} o'clock`;
    }

    return phrase;

}
//split text around punctuation marks.
//Very short sentences are merged together
//we split on multiple punctuation marks
//we want to avoid things like "..." "U.S.A" triggering different say blocks
//this is buggy on some browers that don't support negative lookbehind
//const TEXT_CHUNK_REGEX = /(?<=[\w\s,']{7}[.?!:]+)(?=[\w\s,']{7})/;
const TEXT_CHUNK_REGEX = /(?=[\w\s,']{7})/;

export const QueryType = {
    text: 'text',
    number: 'number',
    boolean: 'boolean'
}

//TODO: needs tests! Not working well
export const textToMemorableChunksOfSpeech = function (textToSpeak) {
    //split a piece of text into a multiple chunks so as not to ask the performer to remember too much at once!
    let chunks = [];
    if (textToSpeak.length <= 60) {
        chunks.push(textToSpeak);
    } else {
        chunks = textToSpeak.split(TEXT_CHUNK_REGEX);
    }
    return chunks;
}

Blockly.Blocks['start'] = {

    init: function () {
        this.appendDummyInput()
            .appendField('Start');
        this.setPreviousStatement(false, null);
        this.setNextStatement(true, null);
        this.setStyle("event_blocks")
    }

};

Blockly.JavaScript['start'] = function () {
    return "\n";
};

export const setupBlockNoArg =  function (blockName, textToDisplay, timeout, options) {
    if(!options){
        options = {};
    }

    //let argName = options.argName ? options.argName : "ARGUMENT";
    let style = options.style? options.style : null;
    let colour = options.colour? options.colour : 230;
    let textForLex = options.textForLex ? options.textForLex : textToDisplay;


    if(Blockly.Blocks[blockName]){
        Logger.warn("Block already defined: "+blockName);
    }
    Blockly.Blocks[blockName] = {
        init: function () {
                this.appendDummyInput()
                    .appendField(textToDisplay);

            this.setInputsInline(true);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            if(style){
                this.setStyle(style)
            }
            else{
                this.setColour(colour);
            }
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };

    Blockly.JavaScript[blockName] = function () {
        return getCommandToSend(`"${textForLex}"`, timeout);
    }
}

export const setupBlockWithInput = function (blockName, textToDisplay, timeout, options) {
    if(!options){
        options = {};
    }

    let argName = options.argName ? options.argName : "ARGUMENT";
    let style = options.style? options.style : null;
    let colour = options.colour? options.colour : 230;
    let textForLex = options.textForLex ? options.textForLex : textToDisplay;
    let tooltip = options.tooltip ? options.tooltip : "";
    let inputType = options.inputType ? options.inputType : "Number"
    let nextStatement = options.nextStatement === false ? options.nextStatement : true

    let textToDisplayBeforeAndAfterArg = textToDisplay.split("{}")
    if(Blockly.Blocks[blockName]){
        Logger.warn("Block already defined: "+blockName);
    }
    Blockly.Blocks[blockName] = {
        init: function () {
            if (textToDisplayBeforeAndAfterArg[0].length > 0) {
                this.appendDummyInput()
                    .appendField(textToDisplayBeforeAndAfterArg[0]);
            }
            if (textToDisplayBeforeAndAfterArg.length > 1) {
                this.appendValueInput(argName)
                    .setCheck(inputType)

                if (textToDisplayBeforeAndAfterArg[1].length > 0) {
                    this.appendDummyInput()
                        .appendField(textToDisplayBeforeAndAfterArg[1]);
                }
            }
            this.setInputsInline(true);
            this.setPreviousStatement(true, null);
            this.setNextStatement(nextStatement, null);
            if(style){
                this.setStyle(style)
            }
            else{
                this.setColour(colour);
            }
            this.setTooltip(tooltip);
            this.setHelpUrl("");
        }
    };

    Blockly.JavaScript[blockName] = function (block) {
        let arg_value = Blockly.JavaScript.valueToCode(block, argName, Blockly.JavaScript.ORDER_ATOMIC) || 0;
        let textToSendBeforeAndAfterArg = textForLex.split("{}")
        let timeoutValue = timeout instanceof Function ? timeout(arg_value) : timeout;
        return getCommandToSend(`"${textToSendBeforeAndAfterArg[0]}" + ${arg_value} + "${textToSendBeforeAndAfterArg.length > 1 ? textToSendBeforeAndAfterArg[1] : ""}"`,
            timeoutValue);
    }

};


export const setupBlockWithInputAndDropdown = function (blockName, textToDisplay, timeout, options) {
    if(!options){
        options = {};
    }

    let argName = options.argName ? options.argName : "ARGUMENT";
    let dropdownName = options.dropdownName ? options.dropdownName : "DROPDOWN";
    let dropdownOptions = options.dropdownOptions ? options.dropdownOptions : [""];
    let style = options.style? options.style : null;
    let colour = options.colour? options.colour : 230;
    let textForLex = options.textForLex ? options.textForLex : textToDisplay;
    let tooltip = options.tooltip ? options.tooltip : "";
    let nextStatement = options.nextStatement === false ? options.nextStatement : true


    const splitRegex = /{.*?}/g
    let textToDisplayBeforeAndAfterArgs = textToDisplay.split(splitRegex)
    const argRegex = /{(.*?)}/g
    let blockArguments = textToDisplay.match(argRegex)

    if(Blockly.Blocks[blockName]){
        Logger.warn("Block already defined: "+blockName);
    }
    Blockly.Blocks[blockName] = {
        init: function () {
            let block = this;
            textToDisplayBeforeAndAfterArgs.forEach(function(text, index) {
                //this should first apply any text before the first argument (ie if length > 0)
                if(text.length > 0){
                    block.appendDummyInput()
                        .appendField(text);
                }
                //then the first argument
                if(blockArguments[index]){
                    let argType = blockArguments[index].substr(1, blockArguments[index].length - 2);
                    if(argType.toLowerCase() === "dropdown"){
                        const mappedOptions = dropdownOptions.map(x => [x,x]);
                        block.appendDummyInput()
                            .appendField(new Blockly.FieldDropdown( mappedOptions), dropdownName)
                    }
                    else{
                        block.appendValueInput(argName)
                            .setCheck(argType)
                    }
                }
                //then iterate through any other text and arguments
            });

            this.setInputsInline(true);
            this.setPreviousStatement(true, null);
            this.setNextStatement(nextStatement, null);
            if(style){
                this.setStyle(style)
            }
            else{
                this.setColour(colour);
            }
            this.setTooltip(tooltip);
            this.setHelpUrl("");
        }
    };

    Blockly.JavaScript[blockName] = function (block) {
        let textToSendBeforeAndAfterArgs = textForLex.split(splitRegex)
        let blockArguments = textForLex.match(argRegex)
        let arg_value;
        let textCommand = textToSendBeforeAndAfterArgs.reduce( function (acc, value, index){
            if(value.length >0){
                acc = `${acc} + "${value}"`;
            }
            if(blockArguments[index]){
                let argType = blockArguments[index].substr(1, blockArguments[index].length - 2);
                if(argType.toLowerCase() === "dropdown"){
                    acc = `${acc} + "${block.getFieldValue(dropdownName)}"`;
                }
                else{
                    arg_value = Blockly.JavaScript.valueToCode(block, argName, Blockly.JavaScript.ORDER_ATOMIC) || 0;
                    acc = `${acc} + "${arg_value}"`;
                }
            }
            return acc;
        }, `""`)

        let timeoutValue = timeout instanceof Function ? timeout(arg_value) : timeout;
        return getCommandToSend(textCommand, timeoutValue);
        // return getCommandToSend(`"${textToSendBeforeAndAfterArg[0]}" + ${arg_value} + "${textToSendBeforeAndAfterArg.length > 1 ? textToSendBeforeAndAfterArg[1] : ""}"`,
        //     timeoutValue);
    }

};


export const setupBlockDropdownArgument = function (blockName, textToDisplay, dropdownOptions, timeout, options) {
    if(!options){
        options = {};
    }

    let argName = options.argName ? options.argName : "ARGUMENT";
    let style = options.style? options.style : null;
    let colour = options.colour? options.colour : 230;
    let textForLex = options.textForLex ? options.textForLex : textToDisplay;

    let textToDisplayBeforeAndAfterArg = textToDisplay.split("{}")
    if(Blockly.Blocks[blockName]){
        Logger.warn("Block already defined: "+blockName);
    }
    Blockly.Blocks[blockName] = {
        init: function () {
            if (textToDisplayBeforeAndAfterArg[0].length > 0) {
                this.appendDummyInput()
                    .appendField(textToDisplayBeforeAndAfterArg[0]);
            }
            if (textToDisplayBeforeAndAfterArg.length > 1) {
                const mappedOptions = dropdownOptions.map(x => [x,x]);
                this.appendDummyInput()
                    .appendField(new Blockly.FieldDropdown( mappedOptions), argName)
                if (textToDisplayBeforeAndAfterArg[1].length > 0) {
                    this.appendDummyInput()
                        .appendField(textToDisplayBeforeAndAfterArg[1]);
                }
            }
            this.setInputsInline(true);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            if(style){
                this.setStyle(style)
            }
            else{
                this.setColour(colour);
            }
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };

    Blockly.JavaScript[blockName] = function (block) {

        let dropdown_value = block.getFieldValue(argName);
        let textToSendBeforeAndAfterArg = textForLex.split("{}")
        return getCommandToSend(`"${textToSendBeforeAndAfterArg[0]}" + "${dropdown_value}" + "${textToSendBeforeAndAfterArg.length > 1 ? textToSendBeforeAndAfterArg[1] : ""}"`,
            timeout);
    }

};

export const generateBlocklyFunctionBody = function(block, functionName){
    // let code = null;
    // let error = null;



    //this is taken from blockly source for generating functions
    //because unfortunately their API doesn't make the function available unless you generate the whole workspace
    let xfix1 = '';
    if (Blockly.JavaScript.STATEMENT_PREFIX) {
        xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_PREFIX, block);
    }
    if (Blockly.JavaScript.STATEMENT_SUFFIX) {
        xfix1 += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX, block);
    }
    if (xfix1) {
        xfix1 = Blockly.JavaScript.prefixLines(xfix1, Blockly.JavaScript.INDENT);
    }
    let loopTrap = '';
    if (Blockly.JavaScript.INFINITE_LOOP_TRAP) {
        loopTrap = Blockly.JavaScript.prefixLines(
            Blockly.JavaScript.injectId(Blockly.JavaScript.INFINITE_LOOP_TRAP, block),
            Blockly.JavaScript.INDENT);
    }
    const branch = Blockly.JavaScript.statementToCode(block, 'STACK');
    let returnValue =
        Blockly.JavaScript.valueToCode(block, 'RETURN', Blockly.JavaScript.ORDER_NONE) || '';
    let xfix2 = '';
    if (branch && returnValue) {
        // After executing the function body, revisit this block for the return.
        xfix2 = xfix1;
    }
    if (returnValue) {
        returnValue = Blockly.JavaScript.INDENT + 'return ' + returnValue + ';\n';
    }
    return 'function ' + functionName + '(){\n' + xfix1 + loopTrap + branch + xfix2 + returnValue + '}';
}




