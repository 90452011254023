var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "blocklyArea", staticClass: "blocklyArea section content" },
    [
      _c("div", {
        ref: "blocklyDiv",
        staticClass: "blocklyDiv",
        staticStyle: { position: "absolute" },
      }),
      _c("blockly-toolbox", {
        attrs: {
          "toolbox-config": _vm.toolboxConfig,
          workspace: _vm.workspace,
          "events-for-task": _vm.eventsForTask,
        },
        on: { updated: _vm.updateToolbox },
      }),
      _c("remote-block-runner", {
        attrs: {
          workspace: _vm.workspace,
          "is-primary": _vm.isPrimaryUser,
          "remote-blocks-for-task": _vm.remoteBlocks,
          "remote-block-message": _vm.remoteBlockMessage,
        },
      }),
      _vm.isPrimaryUser === true
        ? _c("call-remote-block", {
            attrs: {
              workspace: _vm.workspace,
              "remote-blocks": _vm.remoteBlocks,
            },
          })
        : _vm._e(),
      _c("lex-event-handler", {
        attrs: {
          workspace: _vm.workspace,
          eventTriggerMessage: _vm.remoteBlockMessage,
          readOnly: _vm.readOnly,
        },
      }),
      _c(
        "xml",
        { ref: "initialToolbox", staticStyle: { display: "none" } },
        [
          _c(
            "category",
            {
              attrs: {
                name: "Logic",
                categorystyle: "logic_category",
                iconclass: "blocklyTreeIconCustom logic",
              },
            },
            [
              _c("label", {
                attrs: { text: "Logic", "web-class": "blocklyFlyoutHeading" },
              }),
              _c("block", { attrs: { type: "controls_if" } }),
              _c("block", { attrs: { type: "logic_compare" } }),
              _c("block", { attrs: { type: "logic_operation" } }),
              _c("block", { attrs: { type: "logic_negate" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }