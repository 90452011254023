export const STORYTELLING_CATEGORY_XML=`<category name="Storytelling" categorystyle="sensing_category" iconclass="blocklyTreeIconCustom drawing" css-container="blocklyToolboxCategory storytelling">
    <label text="Storytelling" web-class="blocklyFlyoutHeading"></label>`
export const STORY_IMAGINE_XML=`<block type="story_imagine">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const STORY_MEMORY_XML=`<block type="story_saymemory">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`
export const STORY_TELL_LIE_XML=`<block type="story_telllie">
        <value name="TEXT">
            <shadow type="text">
                <field name="TEXT"></field>
            </shadow>
        </value>
    </block>`