
const {getCommandToSend, getQueryToSend} = require("@/blockly/blocks/common");
//Block definitions

Blockly.Blocks['draw_pendown'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Pen down");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };


  Blockly.Blocks['draw_penup'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Pen up");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['draw_moveorthogonal'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Move pen");
      this.appendValueInput("DISTANCE")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField("cm ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([["up","up"], ["down","down"], ["left","left"], ["right","right"]]), "DIRECTION")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['draw_movediagonal'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Move pen");
      this.appendValueInput("DISTANCE")
          .setCheck("Number")          
      this.appendDummyInput()
          .appendField("cm towards ");
      this.appendDummyInput()      
          .appendField(new Blockly.FieldDropdown([
              ["1 o'clock","1 o'clock"], 
              ["2 o'clock","2 o'clock"], 
              ["3 o'clock","3 o'clock"], 
              ["4 o'clock","4 o'clock"], 
              ["5 o'clock","5 o'clock"], 
              ["6 o'clock","6 o'clock"], 
              ["7 o'clock","7 o'clock"], 
              ["8 o'clock","8 o'clock"], 
              ["9 o'clock","9 o'clock"], 
              ["10 o'clock","10 o'clock"], 
              ["11 o'clock","11 o'clock"], 
              ["12 o'clock","12 o'clock"],              
              
            ]), "DIRECTION")          
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['draw_isedge'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Has the pen reached the edge?")                    
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setColour(230);
   this.setTooltip("Test if pen has reached the edge of the board or paper");
   this.setHelpUrl("");
    }
  };
  
  Blockly.Blocks['draw_ispencolour'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Is pen colour");
      this.appendValueInput("COLOUR")        
      this.appendDummyInput()
          .appendField("?");
      this.setInputsInline(true);
      this.setOutput(true, "Boolean");
      this.setColour(230);
   this.setTooltip("Test pen colour");
   this.setHelpUrl("");
    }
  };
  
  //Generators:

  Blockly.JavaScript['draw_pendown'] = function() {
    return getCommandToSend('"Pen down"', 2000);
  };

  Blockly.JavaScript['draw_penup'] = function() {
    return getCommandToSend('"Pen up"', 2000);
  };

  Blockly.JavaScript['draw_moveorthogonal'] = function(block) {
    let value_distance = Blockly.JavaScript.valueToCode(block, 'DISTANCE', Blockly.JavaScript.ORDER_ATOMIC);
    let dropdown_direction = block.getFieldValue('DIRECTION');
    
    return getCommandToSend('"Move the pen " + "'+value_distance+'" + " centimetres " + "' +dropdown_direction+'"', 2000 + 1000 * value_distance);

  };


  Blockly.JavaScript['draw_movediagonal'] = function(block) {
    let value_distance = Blockly.JavaScript.valueToCode(block, 'DISTANCE', Blockly.JavaScript.ORDER_ATOMIC);
    let dropdown_direction = block.getFieldValue('DIRECTION');
    
    return getCommandToSend('"Move the pen " + "'+value_distance+'" + " centimetres towards " + "' +dropdown_direction+'"', 2000 + 1000 * value_distance);

  };

  Blockly.JavaScript['draw_isedge'] = function() {
          
    let code = getQueryToSend('"Has the pen reached the edge?"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['draw_ispencolour'] = function(block) {

    let value_text = Blockly.JavaScript.valueToCode(block, 'COLOUR', Blockly.JavaScript.ORDER_ATOMIC) || "";
    let code = getQueryToSend('"Is pen colour " + "' + value_text + '"');
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };