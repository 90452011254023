//Block definitions:
import {getCommandToSend} from "@/blockly/blocks/common";

Blockly.Blocks['story_imagine'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Imagine you are ");                
          this.appendValueInput("TEXT")
          .setCheck("String")
            
      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("storytelling_blocks");
   this.setTooltip("");
   this.setHelpUrl("");
    }
};

Blockly.Blocks['story_saymemory'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Say something you remember about ");               
          this.appendValueInput("TEXT")
          .setCheck("String")
      
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("storytelling_blocks");
   this.setTooltip("");
   this.setHelpUrl("");

    }
  };

  Blockly.Blocks['story_telllie'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Tell a lie about ");               
          this.appendValueInput("TEXT")
          .setCheck("String")
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle("storytelling_blocks");
   this.setTooltip("");
   this.setHelpUrl("");

    }
  };

  Blockly.JavaScript['story_imagine'] = function(block) {
    var value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    return getCommandToSend('"Imagine you are " + '+value_text, 6000);

  };

  Blockly.JavaScript['story_saymemory'] = function(block) {
    var value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    return getCommandToSend('"Say something you remember about " + '+value_text, 30000);

  };

  Blockly.JavaScript['story_telllie'] = function(block) {
    var value_text = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC) || "";
    return getCommandToSend('"Tell a lie about " + '+value_text, 30000);

  };

