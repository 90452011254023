<template>
  <div>
    <div class="overview-container" v-if="status === 'active' && inTaskOverview === true">
      <div v-html="overviewHtml"></div>
      <span class="show-blocks-link">
<!--            We may want to make this customisable-->
                <a class="btn" href="#" v-on:click="viewBlocks()">View blocks for task</a>
            </span>
    </div>
    <div class="task-details" v-if="status ==='active' && inTaskOverview === false">
      <h1 v-if="title">{{ title }}</h1>
      <div class="description" v-html="description"></div>
      <b-modal v-if="isPrimaryUser===false" id="pause-modal" title="LexEdit is paused"
               v-model="isPaused"
               :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true"
               :hide-footer="true">
        <p class="my-4">Please wait for further instructions...</p>
      </b-modal>

      <!--quick hack to enable getting admin mode -->
      <!--                <div v-if="studentName === 'RussellBender'">-->
      <!--                  <button v-on:click="getBlocksBase64">Get block code</button>-->
      <!--                  <textarea id="generated-code" style="display: none;"></textarea>-->
      <!--                </div>-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import {BModal} from "bootstrap-vue";
import {mapState} from "vuex";
export default {
  name: 'ActiveTaskViewer',
  components: {BModal},
  props: {
    isPrimaryUser: Boolean,
    auth: String,
    currentTaskMessage: Object
  },
  data: function () {
    return {
      id: null,
      sessionId: null,
      sessionName: null,
      status: null,
      title: '',
      description: '',
      maxBlocks: Infinity,
      initialBlocks: '',
      overviewHtml: '',
      //showOverview: null,
      disableOrphans: false,
      isPaused: false
    }
  },
  computed: mapState(['inTaskOverview']),
  watch: {
    currentTaskMessage: {
      handler: 'processCurrentTaskMessage'
    },
    // showOverview: function(newValue){
    //   this.$root.$emit("overview-set", newValue)
    // }

  },
  methods: {
    processCurrentTaskMessage: function(data) {
      let msgType = data.message;
      if (msgType === 'startSession') {
        this.loadTaskFromMessage(data);
      } else if (msgType === 'stopSession') {
        this.stopSession();

      } else if (msgType === 'pauseSession') {
        this.isPaused = true;
      } else if (msgType === 'resumeSession') {
        this.isPaused = false;
      }else if (msgType === 'markSuccessful') {
        //we're not interested currently in this
      }
    },
    loadTaskFromMessage: function (taskDetails) {

      //TODO: Do we want to save / warn students if we're in a previous task?

      //this.successful = false;
  //TODO: Can put more of this in store? And don't need to process this message here at all...
      this.id = taskDetails.id;
      this.sessionId = taskDetails.taskSessionId;
      this.sessionName = taskDetails.sessionName;
      this.status = 'active';
      this.title = taskDetails.title;
      this.description = taskDetails.description;
      this.isPaused = taskDetails.isPaused;
      //todo: placeholder. Move to the sessions.
      this.initialBlocks = `<xml><block type="start" deletable="false" movable="false"></block></xml>`;
      if (taskDetails.initialBlocks) {
        this.initialBlocks = atob(taskDetails.initialBlocks);
      }

      let self = this;
      //only show overview for primary users:
      if (taskDetails.overviewUrl && this.isPrimaryUser) {
        $.ajax({
          url: taskDetails.overviewUrl,
          type: 'GET'
        })
            .done((data) => {
              self.overviewHtml = data;
              //self.showOverview = true
              this.$store.commit('setInTaskOverview', true);
            })
            .fail(jqXHR => Logger.error(JSON.stringify(jqXHR)))

      } else {
        this.$store.commit('setInTaskOverview', false);
      }

      this.$store.commit('setActiveTask', {
        taskId: this.id,
        sessionName: this.sessionName,
        sessionId: this.sessionId
      });



    },


    stopSession: function () {
      //TODO: Do we want to notify students?

      this.status = null;
      this.id = null;
      this.title = '';
      this.maxBlocks = Infinity;
      this.initialBlocks = '';
      this.overviewHtml = '';
      //this.showOverview = null;
      //this.customToolbox = null;

      this.$store.commit('clearActiveTask')
      // this.$root.$emit("task-ended")

    },
    viewBlocks(){
      this.$store.commit('setInTaskOverview', false);
      if(this.isPrimaryUser){
        $.ajax({
          url: `/api/broadcast/sendMessage?content=${encodeURI("entering diagnostics mode")}&displayImage=DIAGNOSTICS`,
          type: 'PUT',
          headers: {'Authorization': this.auth}
        });
      }
    }
  }

}
</script>


